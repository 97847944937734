import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAppointmentRequest } from "../../types/Entities";
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import dayjs from "dayjs";
import React from "react";
import {
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppointmentRequestSampleIcon from "../../icons/appointmentRequestSampleIcon";
import RoundedContentBox from "../general/roundedContentBox";
import BigRoundButton from "../general/bigRoundButton";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import styles from "./appointmentRequestOpen.module.css";

interface Props {
  subdomain: string;
}
export function AppointmentRequestOpen(props: Props) {
  const [appointmentRequest, setAppointmentRequest] = useState<
    IAppointmentRequest[] | any
  >([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState<any>([]);
  const [
    upcomingAppointmentRequestsManager,
    setUpcomingAppointmentRequestsManager,
  ] = useState<any>([]);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointSm = useMediaQuery(theme.breakpoints.up("sm"));

  const formattedDates = (item: any) => {
    const sortedDays = item?.available_day_set.sort((a: any, b: any) =>
      dayjs(a).diff(dayjs(b))
    );

    const ranges = [];
    let rangeStart = sortedDays[0];
    let previousDate = sortedDays[0];

    for (let i = 1; i < sortedDays.length; i++) {
      const currentDate = sortedDays[i];
      const previoudDay = dayjs(previousDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      if (currentDate !== previoudDay) {
        ranges.push([rangeStart, previousDate]);
        rangeStart = currentDate;
      }
      previousDate = currentDate;
    }
    ranges.push([rangeStart, previousDate]);

    const formattedRanges = ranges.map(([start, end]) => {
      if (start === end) {
        return dayjs(start).format("DD.MM.YYYY");
      } else
        return `${dayjs(start).format("DD.MM.YYYY")} - ${dayjs(end).format(
          "DD.MM.YYYY"
        )}`;
    });
    return formattedRanges.join(", ");
  };

  const appointmentLocation = (item: any) => {
    const { name, street_house, zip, city } = item.building_information;

    const locationParts = [];

    if (name) locationParts.push(name);
    if (street_house) locationParts.push(street_house);

    if (zip || city) {
      const combined = [zip, city].filter((part) => part !== null).join(" ");
      locationParts.push(combined);
    }

    return locationParts.join(", ");
  };

  useEffect(() => {
    api.genericApiRequest({
      entity: "appointmentRequest",
      method: "get",
      successHandler: (res: any) => {
        setAppointmentRequest(res.data.results);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);
  useEffect(() => {
    const additionalUrlParameters: any = {};
    const dateToday = new Date().setHours(12, 0, 0, 0);
    const formattedDate = dayjs(dateToday).toISOString();

    additionalUrlParameters["categoryexamination_set"] = "true";
    additionalUrlParameters["status__in"] =
      "open,prefilled_by_user,in_progress,review_preparation_required,waiting_for_review,in_review,post_processing_required,completed";
    additionalUrlParameters["datetime__gte"] = formattedDate;
    api.genericApiRequest({
      entity: "appointment",
      method: "get",
      parametersToRender: {
        depth: "1",
        additionalUrlParameters: additionalUrlParameters,
      },

      successHandler: (res: any) => {
        setUpcomingAppointments(res.data.results);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);
  useEffect(() => {
    if (props.subdomain === "manage") {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["categoryexamination_set"] = "true";
      api.genericApiRequest({
        entity: "dashboard",
        method: "get",
        parametersToRender: {
          depth: "1",
          additionalUrlParameters: additionalUrlParameters,
        },

        successHandler: (res: any) => {
          if (
            res.data &&
            res.data.upcoming_appointment_requests !== undefined
          ) {
            setUpcomingAppointmentRequestsManager(
              res.data.upcoming_appointment_requests
            );
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [props.subdomain]);
  // ----------AppointmentRequests Portal/Company self----------
  let upcomingAppointmentRequestListItems = <></>;
  if (appointmentRequest && appointmentRequest !== undefined) {
    upcomingAppointmentRequestListItems = appointmentRequest.map(
      (item: any) => {
        return (
          <React.Fragment key={item.id + item.title}>
            <ListItem
              className={styles.listItem}
              sx={{
                "&& .MuiListItemSecondaryAction-root": {
                  position: "relative",
                  paddingLeft: "2rem",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <ListItemIcon className={styles.listItemIcon}>
                <AppointmentRequestSampleIcon
                  width="2.5rem"
                  height="2.5rem"
                  withBackground={true}
                />
              </ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ component: "div" }}
                primary={item.title}
                secondary={
                  <>
                    <Typography
                      className={styles.displayInline}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {formattedDates(item)}
                    </Typography>
                    <Typography
                      className={styles.displayBlock}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {item.examination_set.map((examination: any) => {
                        return (
                          <ListItemText
                            secondaryTypographyProps={{ component: "div" }}
                            key={examination.id + examination.name}
                            primary={
                              <Typography
                                className={styles.displayBlock}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {examination.name}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                                sx={{ whiteSpace: "preserve" }}
                              >
                                {examination.description}
                              </Typography>
                            }
                          ></ListItemText>
                        );
                      })}
                    </Typography>
                  </>
                }
              ></ListItemText>
              {/* This is for Other devices */}
              {breakpointSm && (
                <BigRoundButton
                  onClick={() =>
                    navigate(
                      "/appointmentrequestopen/" + `${item.id}` + "/pick"
                    )
                  }
                  buttonHeight="2.25rem"
                  buttonWidth="2.25rem"
                />
              )}
            </ListItem>
            {/* This is for Mobile devices */}
            {!breakpointSm && (
              <div className={styles.mobileButtonContainer}>
                <BigRoundButton
                  onClick={() =>
                    navigate(
                      "/appointmentrequestopen/" + `${item.id}` + "/pick"
                    )
                  }
                  buttonHeight="2.25rem"
                  buttonWidth="40vw"
                  borderRadius="0.5rem"
                />
              </div>
            )}
          </React.Fragment>
        );
      }
    );
  }

  //---------- Manager for Company ----------
  let upcomingAppointmentRequestManagerListItem = <></>;
  if (
    upcomingAppointmentRequestsManager &&
    upcomingAppointmentRequestsManager !== undefined
  ) {
    upcomingAppointmentRequestManagerListItem =
      upcomingAppointmentRequestsManager.map((item: any) => {
        return (
          <React.Fragment key={item.title + item.start_day}>
            <ListItem
              className={styles.listItemNoAlign}
              sx={{
                "&& .MuiListItemSecondaryAction-root": {
                  position: "relative",
                  paddingLeft: "2rem",
                  whiteSpace: "nowrap",
                },
              }}
              secondaryAction={breakpointSm && formattedDates(item)}
            >
              <ListItemIcon className={styles.listItemIconNoMarginTop}>
                <AppointmentRequestSampleIcon
                  width="2.5rem"
                  height="2.5rem"
                  withBackground={true}
                />
              </ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ component: "div" }}
                primary={
                  breakpointSm
                    ? item.title
                    : item.title + " " + formattedDates(item)
                }
                secondary={
                  <>
                    <ListItemText primary={appointmentLocation(item)} />
                    {item.department_set.map((department: any, index: any) => {
                      const length = item.department_set.length - 1;
                      return index !== length
                        ? department.name + ", "
                        : department.name;
                    })}
                  </>
                }
              />
            </ListItem>
            <div className={styles.linearProgressContainer}>
              <LinearProgress
                variant="determinate"
                value={Math.round(
                  (100 / item.invited_user_count) * item.accepted_user_count
                )}
                className={styles.linearProgress}
                classes={{ bar: styles.linearProgressBar }}
              />
              <Typography
                variant="caption"
                pt={2}
                className={styles.linearProgressText}
              >{`Angemeldet: ${item.accepted_user_count} / ${item.invited_user_count}`}</Typography>
            </div>
          </React.Fragment>
        );
      });
  }
  // --------------------

  // -------------------- Appointments --------------------
  let upcomingAppointmentListItems = <></>;
  if (upcomingAppointments && upcomingAppointments !== undefined) {
    const filteredItems = upcomingAppointments.filter((item: any) => {
      const categoryExaminationSet = item.categoryexamination_set;

      return categoryExaminationSet.length !== 0;
    });
    upcomingAppointmentListItems = filteredItems.map((item: any) => {
      const appointmentDate = new Date(item.datetime).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const appointmentTime = new Date(item.datetime).toLocaleString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return (
        <React.Fragment key={item.id}>
          <ListItem
            className={styles.listItem}
            sx={{
              "&& .MuiListItemSecondaryAction-root": {
                paddingLeft: "2rem",
              },
            }}
          >
            <ListItemIcon className={styles.listItemIcon}>
              <AppointmentRequestSampleIcon
                width="2.5rem"
                height="2.5rem"
                withBackground={true}
              />
            </ListItemIcon>
            <ListItemText
              secondaryTypographyProps={{ component: "div" }}
              primary={item.categoryexamination_set[0].examination_name}
              secondary={
                <>
                  <Typography
                    className={styles.displayInline}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {appointmentDate + " - " + appointmentTime}
                  </Typography>
                  <ListItemText primary={appointmentLocation(item)} />
                  {item.categoryexamination_set.map((catExamination: any) => {
                    return (
                      <ListItemText
                        secondaryTypographyProps={{ component: "div" }}
                        key={
                          catExamination.id + catExamination.examination_name
                        }
                        primary={
                          <Typography
                            className={styles.displayBlock}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {catExamination.examination_name}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            className={styles.displayBlock}
                            sx={{ whiteSpace: "preserve" }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {catExamination.examination_description}
                          </Typography>
                        }
                      ></ListItemText>
                    );
                  })}
                </>
              }
            ></ListItemText>
          </ListItem>
        </React.Fragment>
      );
    });
  }
  // ----------------------------------------
  return (
    <Grid container rowSpacing={"1rem"}>
      <Grid item xs={12}>
        <h1 className={styles.appointmentsHeading}>
          {props.subdomain === "manage"
            ? t("navigation_manage_appointments")
            : t("appointment_multi")}
        </h1>
      </Grid>

      {/* AppointmentRequest Portal/Company self */}
      <Grid item xs={12}>
        {Array.isArray(upcomingAppointmentRequestListItems) &&
          upcomingAppointmentRequestListItems.length !== 0 && (
            <Grid item xs={12}>
              <RoundedContentBox
                padding={!breakpointSm ? "0.25rem" : undefined}
              >
                <List
                  aria-labelledby="appointmentRequestSubheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      id="appointmentRequestSubheader"
                      className={styles.appointmentsSubheader}
                    >
                      {t("appointmentrequest_open")}
                    </ListSubheader>
                  }
                >
                  {upcomingAppointmentRequestListItems}
                </List>
              </RoundedContentBox>
            </Grid>
          )}
      </Grid>
      {/* Appointments */}
      <Grid item xs={12}>
        {Array.isArray(upcomingAppointmentListItems) &&
          upcomingAppointmentListItems.length !== 0 && (
            // upcomingAppointmentListItems[0] !== undefined &&
            <Grid item xs={12}>
              <RoundedContentBox
                padding={!breakpointSm ? "0.25rem" : undefined}
              >
                <List
                  aria-labelledby="appointmentSubheader"
                  subheader={
                    <ListSubheader
                      component="span"
                      id="appointmenSubheader"
                      className={styles.appointmentsSubheader}
                    >
                      {t("appointments_booked")}
                    </ListSubheader>
                  }
                >
                  {upcomingAppointmentListItems}
                </List>
              </RoundedContentBox>
            </Grid>
          )}
      </Grid>
      {/* AppointmentRequests on Manager for Company */}
      {props.subdomain === "manage" &&
        Array.isArray(upcomingAppointmentRequestManagerListItem) &&
        upcomingAppointmentRequestManagerListItem.length !== 0 && (
          <Grid item xs={12}>
            <RoundedContentBox padding={!breakpointSm ? "0.25rem" : undefined}>
              <List
                aria-labelledby="appointmentRequestManagerSubheader"
                subheader={
                  <ListSubheader
                    component="span"
                    id="appointmentRequestManagerSubheader"
                  >
                    {t("appointmentrequest_company")}
                  </ListSubheader>
                }
              >
                {upcomingAppointmentRequestManagerListItem}
              </List>
            </RoundedContentBox>
          </Grid>
        )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Grid>
  );
}
