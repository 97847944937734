import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import { DotLoader } from "react-spinners";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import { t } from "i18next";
import {
  Chip,
  FormControl,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TinyMceEditor from "../tinymce/tinymce";
import { InputWithRef } from "../forms/inputsWithRef/inputWithRef";
import styles from "./mailTemplate.module.css";
import { TextArea } from "../forms/inputs/textArea";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

type EditorType = "editor" | "textarea";

const MailTemplateEditor: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [mailTemplateName, setMailTemplateName] = useState<any>("");
  const [mailTemplateTags, setMailTemplateTags] = useState<any>([]);
  const [previewContent, setPreviewContent] = useState("");
  const [wrapperTemplateForPreview, setWrapperTemplateFotPreview] =
    useState<any>(null);
  const [editortype, setEditorType] = useState<EditorType>("editor");

  const { register, handleSubmit, control, watch, formState, reset } =
    useForm<any>();

  const { mailTemplateId } = useParams() as { mailTemplateId: string };
  const navigate = useNavigate();
  const { isDirty } = formState;
  const { showConfirmationModalAsync } = useConfirmationModal();

  const handleNavigate = async () => {
    console.log(isDirty);
    if (isDirty) {
      const isConfirmed = await showConfirmationModalAsync(
        t("confirm_go_back")
      );
      if (!isConfirmed) {
        return;
      }
    }
    navigate("/settings/mailTemplateList");
  };

  useEffect(() => {
    if (mailTemplateId !== undefined) {
      getMailTemplate();
    }
  }, [mailTemplateId]);

  useEffect(() => {
    if (mailTemplateName !== "") {
      getMailPreviewWrapperTemplate();
    }
    if (mailTemplateName !== "") {
      getAvailableMailTemplateTags();
    }
  }, [mailTemplateName]);

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
  };
  const getMailPreviewWrapperTemplate = () => {
    const additionalUrlParameter: any = {};
    additionalUrlParameter["name__in"] = "wrapper_template";

    api.genericApiRequest({
      method: "get",
      entity: "mailTemplate",
      parametersToRender: { additionalUrlParameters: additionalUrlParameter },
      successHandler: (res: any) => {
        if (res.data.results.length !== 0) {
          const wrapperForPreview = res.data.results[0].mail_body;
          setWrapperTemplateFotPreview(wrapperForPreview);
        }
        setIsLoading(false);
      },

      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const getMailTemplate = () => {
    api.genericApiRequest({
      method: "get",
      entity: "mailTemplate",
      entityId: mailTemplateId,
      successHandler: (res: any) => {
        const data = res.data;
        setMailTemplateName(data.name);
        reset({
          name: data.name || "",
          mail_subject: data.mail_subject || "",
          mail_body: data.mail_body || "",
        });

        if (data.name === "wrapper_template") {
          setEditorType("textarea");
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const getAvailableMailTemplateTags = () => {
    const additionalUrlParameter: any = {};
    additionalUrlParameter["name"] = mailTemplateName;
    const additionalRouteParts: any = {};
    additionalRouteParts["available_tags"] = "/";

    if (mailTemplateName !== "") {
      api.genericApiRequest({
        entity: "mailTemplate",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameter,
        },
        additionalRouteParts: additionalRouteParts,
        successHandler: (res: any) => {
          setMailTemplateTags(res.data.results);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  };

  const submitMailTemplate = (data: any) => {
    setIsLoading(true);
    const result = data;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      method: "put",
      entity: "mailTemplate",
      entityId: mailTemplateId,
      config: config,
      submitData: result,
      successHandler: (res: any) => {
        setShowNotification(true);
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setIsLoading(false);
        navigate(`/settings/mailTemplateEditor/${res.data.id}`);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const tagList = mailTemplateTags.map((name: any, index: any) => {
    return (
      <Chip
        key={name + index}
        label={name}
        className={styles.taglist_chipItem}
      />
    );
  });

  const handleContentChange = (inputValue: any) => {
    if (wrapperTemplateForPreview !== null) {
      const content =
        editortype === "editor" ? inputValue : inputValue.target.value;
      const updatedTemplate = wrapperTemplateForPreview.replace(
        "{{ mail_content }}",
        content
      );
      setPreviewContent(updatedTemplate);
    }
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <>
          <Tooltip
            placement="right"
            title={t("cancel") + " / " + t("back")}
            sx={{ position: "sticky", top: "0" }}
          >
            <IconButton onClick={handleNavigate}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Paper
            sx={{
              padding: "0.75rem",
              borderRadius: "0.75rem",
              maxWidth: "65%",
              margin: "0 auto",
              overflow: "scroll",
            }}
          >
            <form onSubmit={handleSubmit(submitMailTemplate)} noValidate>
              <InputWithRef
                label={t("name")}
                {...register("name", {
                  required: t("field_required"),
                })}
              />
              <InputWithRef
                label={t("mail_subject")}
                {...register("mail_subject")}
              />

              {editortype === "editor" ? (
                <Controller
                  name="mail_body"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <TinyMceEditor
                        showFooter={false}
                        value={value}
                        onChange={(content: any) => {
                          onChange(content);
                          handleContentChange(content);
                        }}
                      />
                    </FormControl>
                  )}
                />
              ) : (
                <Controller
                  name="mail_body"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <TextArea
                        rows={15}
                        fieldClassName={styles.textArea}
                        value={value}
                        onChange={(content: any) => {
                          onChange(content);
                          handleContentChange(content);
                        }}
                      />
                    </FormControl>
                  )}
                />
              )}

              <section className={styles.taglist_container}>{tagList}</section>
              <section style={{ display: "flex", justifyContent: "flex-end" }}>
                <GenericButton type="submit" data-testid="submit-button">
                  {t("save")}
                </GenericButton>
              </section>
            </form>
          </Paper>
          <Paper
            sx={{
              padding: "0.75rem",
              borderRadius: "0.75rem",
              maxWidth: "65%",
              margin: "2rem auto",
              overflow: "scroll",
              width: "100%",
            }}
          >
            <Typography variant="h5" color={"GrayText"}>
              {t("preview")}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  mailTemplateName === "wrapper_template"
                    ? watch("mail_body")
                    : previewContent,
              }}
            />
          </Paper>
        </>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
            setIsLoading(false);
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </div>
  );
};

export default MailTemplateEditor;
