import { t } from "i18next";
import { api } from "../api";
import { useState } from "react";
import { mimeTypeToExtension } from "../mimetypeToExtension";
import { useConfirmationModal } from "../../context/confirmationModalContext";

//mainly for the FileExplorer / FileList combiniation currently built in

type ActionType =
  | "markAsInvisibleToSubject"
  | "markAsVisibleToSubject"
  | "markAsLocked"
  | "markAsUnLocked"
  | "markAsReviewed"
  | "markAsUnreviewed";

const useFileAction = (
  setError: (error: string | null) => void, //setting Errors within the component errorModal
  selectedChildCompanySubjectId?: string //optional handed in directly to the hook for downloadFunction because of componentStructure
) => {
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [moveFileSource, setMoveFileSource] = useState<any>([]);
  const [modalMoveOpen, setModalMoveOpen] = useState<boolean>(false);
  const [moveFileTarget, setMoveFileTarget] = useState<string | null>(null);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const { showConfirmationModalAsync } = useConfirmationModal();

  const updateMoveFileSource = (newData: any) => setMoveFileSource(newData);
  const updateUploadSuccess = (newData: any) => setUploadSuccess(newData);

  const updateMoveFileTarget = (target: string | null) =>
    setMoveFileTarget(target);
  const toggleModalMoveOpen = (isOpen: boolean) => setModalMoveOpen(isOpen);
  const toggleModalEditOpen = (isOpen: boolean) => setModalEditOpen(isOpen);

  //MARKING FILES (lock/unlock - reviewed/unreviewed - visible/invisible)
  const performFileAction = async (
    item: any,
    actionType: ActionType,
    onSuccess?: (updatedFile: any) => void,
    skipQuestion = false
  ) => {
    if (!skipQuestion) {
      const confirmationMessages: Record<ActionType, string> = {
        markAsInvisibleToSubject: "set_invisible_to_subject",
        markAsVisibleToSubject: "set_visible_to_subject",
        markAsLocked: "set_locked",
        markAsUnLocked: "set_unlocked",
        markAsReviewed: "set_status_to_reviewed_for_item",
        markAsUnreviewed: "set_status_to_unreviewed_for_item",
      };

      const isConfirmed = await showConfirmationModalAsync(
        t(confirmationMessages[actionType], {
          itemName: item.name,
        })
      );
      if (!isConfirmed) return;
    }

    const result = {
      visible_to_subject:
        actionType === "markAsInvisibleToSubject"
          ? false
          : actionType === "markAsVisibleToSubject"
          ? true
          : undefined,
      locked:
        actionType === "markAsLocked"
          ? true
          : actionType === "markAsUnLocked"
          ? false
          : undefined,
    };

    const additionalRouteParts: Record<string, string> | undefined =
      actionType === "markAsReviewed"
        ? { mark_reviewed: "/" }
        : actionType === "markAsUnreviewed"
        ? { mark_unreviewed: "/" }
        : undefined;

    const hasValidData = Object.keys(result).some(
      (key) => result[key as keyof typeof result] !== undefined
    );

    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        updateMoveFileSource([]);
        updateUploadSuccess(true);
        const updatedFile: any = { id: item.id };

        switch (actionType) {
          case "markAsInvisibleToSubject":
          case "markAsVisibleToSubject":
            updatedFile.visible_to_subject = result.visible_to_subject;
            break;
          case "markAsLocked":
          case "markAsUnLocked":
            updatedFile.locked = result.locked;
            break;
          case "markAsReviewed":
            updatedFile.reviewed = true;
            break;
          case "markAsUnreviewed":
            updatedFile.reviewed = false;
        }
        onSuccess?.(updatedFile);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      submitData: hasValidData ? result : undefined,
      additionalRouteParts,
    });
  };
  // MOVE FILE ARROUND FOLDERS
  const moveFile = (onSuccess?: (updatedFile: any) => void) => {
    if (!moveFileTarget || !moveFileSource) return;

    const sourceResult = { folder_id: moveFileTarget };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        updateMoveFileSource([]);
        updateUploadSuccess(true);
        onSuccess?.(String(moveFileSource?.id));
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(moveFileSource?.id),
      submitData: sourceResult,
      config,
    });

    setModalMoveOpen(false);
  };

  // EDIT FILENAME
  const editFileName = (
    modalValue: any,
    selectedChildCompany: any,
    modalPresetValues: any,
    onSuccess?: (updatedFile: any) => void
  ) => {
    const result = {
      name: modalValue.name,
      impersonate_subject: selectedChildCompany.subjectId,
    };
    console.log("modalPresetValues in Hook Edit:", modalPresetValues);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        updateMoveFileSource([]);
        updateUploadSuccess(true);
        const updatedFile = { id: modalPresetValues.id, name: result.name };
        onSuccess?.(updatedFile);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(modalPresetValues?.id),
      submitData: result,
      config,
    });

    setModalEditOpen(false);
  };

  //  DELETE a File (single)
  const deleteFile = async (
    item: any,
    onSuccess?: (updatedFile: any) => void,
    skipQuestion = false
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("delete_item_question", { itemName: item.name })
      );
      if (!isConfirmed) return;
    }

    api.genericApiRequest({
      entity: "file",
      method: "delete",
      successHandler: () => {
        updateMoveFileSource([]);
        updateUploadSuccess(true);
        onSuccess?.(item.id);
      },
      failHandler: (error: any) => setError(error),
      entityId: String(item.id),
    });
  };

  //Download File (single & Batch)
  const handleDownloadFile = async (row: any) => {
    const failedDownloads: any = [];

    if (Array.isArray(row)) {
      const JSZip = (await import("jszip")).default;
      const zip = new JSZip();
      let filesAdded = 0;

      for (const file of row) {
        const additionalRouteParts = { download: "/" };
        const additionalUrlParameters: any = {};

        if (
          selectedChildCompanySubjectId &&
          selectedChildCompanySubjectId !== ""
        ) {
          additionalUrlParameters["impersonate_subject"] =
            selectedChildCompanySubjectId;
        }

        try {
          const response: any = await new Promise((resolve, reject) => {
            api.genericApiRequest({
              method: "get",
              responseType: "blob",
              entity: "file",
              entityId: file.id,
              additionalRouteParts,
              parametersToRender: additionalUrlParameters,
              successHandler: (res: any) => resolve(res),
              failHandler: (error: any) => reject(error),
            });
          });

          const blob = response.data;
          const contentType = response.headers.get("Content-Type");
          const extension = contentType
            ? mimeTypeToExtension(contentType)
            : "bin";
          const filename = `${file.name}.${extension}`;

          zip.file(filename, blob);
          filesAdded++;
        } catch (error) {
          failedDownloads.push(t("filedownload_error", { title: file.name }));
        }
      }

      if (filesAdded > 0) {
        zip
          .generateAsync({ type: "blob", compression: "STORE" })
          .then((content) => {
            const blobUrl = URL.createObjectURL(content);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = "kaerDocuments.zip";
            link.click();
            URL.revokeObjectURL(blobUrl);
          });
      }

      if (failedDownloads.length > 0) {
        setError(
          failedDownloads.map((err: any, index: any) => (
            <div key={index}>{err}</div>
          ))
        );
      }
    } else {
      let url = `/api/v1/file/${row.id}/download/`;
      console.log("HERE", row);
      if (selectedChildCompanySubjectId !== "") {
        url = `/api/v1/file/${row.id}/download/?impersonate_subject=${selectedChildCompanySubjectId}`;
      }

      window.location.href = url;
    }
  };

  //mark File as Downloaded used in FileTable
  const markFileAsDownloaded = (
    id: string,
    subdomain: string,
    onSuccess?: (updatedFile: any) => void
  ) => {
    if (subdomain !== "admin") {
      const result = { impersonate_subject: selectedChildCompanySubjectId };
      const routeParts: any = {};
      routeParts["mark_downloaded"] = "/";
      api.genericApiRequest({
        entity: "file",
        method: "put",
        entityId: id,
        additionalRouteParts: routeParts,
        shouldNotImpersonate: true,
        submitData: result,
        successHandler: () => {
          const updatedFile = { id: id, has_been_downloaded: true };
          onSuccess?.(updatedFile);
        },
      });
    }
  };

  return {
    performFileAction,
    uploadSuccess,
    updateUploadSuccess,
    moveFileSource,
    moveFileTarget,
    modalMoveOpen,
    toggleModalMoveOpen,
    updateMoveFileSource,
    updateMoveFileTarget,
    moveFile,
    toggleModalEditOpen,
    editFileName,
    modalEditOpen,
    deleteFile,
    handleDownloadFile,
    markFileAsDownloaded,
  };
};

export default useFileAction;
