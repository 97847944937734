import { Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { t } from "i18next";
import Modal from "../modal";
import FileUploadInput from "../forms/inputs/upload";
import EmailIcon from "@mui/icons-material/Email";
import { api } from "../../helper/api";
import GenericNotification from "../notification/genericNotification";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import styles from "./appointmentTreeItemUploadButton.module.css";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  useCase: "formOverview" | "documentWidget" | "button";
  subject?: any;
  buttonCaseTitle?: any;
  buttonCaseOnClick?: () => void;
  buttonCaseDisabled?: boolean;
  margin?: string;
  wrapperStyle?: any;
  buttonCaseIcon?: any;
  handleTriggerReload?: () => void;
}

const AppointmentTreeItemUploadButton: FC<Props> = ({
  useCase,
  subject,
  buttonCaseTitle,
  buttonCaseOnClick,
  buttonCaseDisabled,
  margin,
  wrapperStyle,
  buttonCaseIcon,
  handleTriggerReload,
}) => {
  const [currentSubject, setCurrentSubject] = useState<any>();
  const [modalUploadOpen, setModalUploadOpen] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [error, setError] = useState<any>();

  const { showConfirmationModalAsync } = useConfirmationModal();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };
  useEffect(() => {
    if (subject) {
      setCurrentSubject(subject);
    }
  }, []);
  let buttonTitle = "";

  //ohne function
  if (!currentSubject?.is_registered) {
    buttonTitle = "Achtung! Proband ist noch nicht registriert!";
  }

  if (currentSubject?.is_analog && !currentSubject?.is_registered) {
    buttonTitle = "EVK fehlt! Bitte hochladen!";
  }

  const handleSendRegistrationEmail = async (
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("send_registration_email_question", {
          email: currentSubject?.email,
        })
      );
      if (!isConfirmed) return;
    }
    const additionalRouteParts: any = {};
    additionalRouteParts["send_user_registration_email"] = "/";

    api.genericApiRequest({
      entity: "subject",
      method: "post",
      submitData: {},
      successHandler: () => {
        setNotificationMessage(t("successfully_sent"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: currentSubject?.id,
      additionalRouteParts: additionalRouteParts,
    });
  };

  const FormOverviewCase = () => {
    return (
      <div style={wrapperStyle}>
        {!currentSubject?.is_registered && (
          // <Grid item xs={"auto"}>
          <GeneralListItem
            allowHover={true}
            onClickElement="wrapper"
            hideButton={true}
            margin={margin}
            wrapperClassName={styles.generalListItemWrapper__sendEmailBtn}
            onClick={() => handleSendRegistrationEmail(false)}
          >
            <Tooltip title={t("send_registration_email")} placement="top-end">
              <div className={styles.buttonContainer}>
                <h6 className={styles.noMargin}>
                  {t("send_registration_email")}
                </h6>
                <EmailIcon />
              </div>
            </Tooltip>
          </GeneralListItem>
          // </Grid>
        )}
        {/* <Grid item xs={"auto"}> */}
        <GeneralListItem
          allowHover={true}
          onClickElement="wrapper"
          hideButton={true}
          margin={margin}
          wrapperClassName={styles.generalListItemWrapper__uploadBtn}
          onClick={() => setModalUploadOpen(true)}
        >
          <Tooltip title={t("upload")} placement="top-end">
            <div className={styles.buttonContainer}>
              <h6 className={styles.noMargin}>{buttonTitle}</h6>
              <FileUploadIcon />
            </div>
          </Tooltip>
        </GeneralListItem>
        {/* </Grid> */}
      </div>
    );
  };

  const DocumentWidgetCase = () => {
    return (
      <>
        <Tooltip placement="top" title={t("upload")}>
          <GenericButton
            className={styles.uploadButton}
            variant="icon"
            onClick={() => setModalUploadOpen(true)}
          >
            <FileUploadIcon />
          </GenericButton>
        </Tooltip>
      </>
    );
  };

  const ButtonCase = () => {
    return (
      <GeneralListItem
        allowHover={true}
        margin={margin}
        onClickElement="wrapper"
        hideButton={true}
        wrapperClassName={
          !buttonCaseDisabled
            ? styles.generalListItemWrapper__BtnCase
            : styles.generalListItemWrapper__BtnCase_disabled
        }
        onClick={buttonCaseOnClick}
        isDisabled={buttonCaseDisabled}
      >
        <div className={styles.buttonContainer}>
          <h6 className={styles.noMargin}>{buttonCaseTitle}</h6>
          {buttonCaseIcon}
        </div>
      </GeneralListItem>
    );
  };

  const renderElement = () => {
    switch (useCase) {
      case "formOverview":
        return <FormOverviewCase />;
      case "documentWidget":
        return <DocumentWidgetCase />;
      case "button":
        return <ButtonCase />;
      default:
        return <p>Unknown status</p>;
    }
  };

  return (
    <div>
      {renderElement()}

      <Modal
        open={modalUploadOpen}
        onClose={() => setModalUploadOpen(false)}
        title={t("upload_file")}
        hideDialogActions={true}
      >
        <FileUploadInput
          multiple={true}
          subjectId={currentSubject?.id}
          folderId={"11111111-1111-1111-1111-888888888888"}
          name="file"
          evkUpload={true}
          onCancel={() => {
            setModalUploadOpen(false);
          }}
          handleUploadSuccess={(modalOpen: boolean) => {
            if (modalOpen) {
              setModalUploadOpen(false);
              handleTriggerReload?.();
            }
          }}
        />
      </Modal>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </div>
  );
};

export default AppointmentTreeItemUploadButton;
