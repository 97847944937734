import { useEffect, useState } from "react";
import Select, { MenuPlacement } from "react-select";
import { IDropDownOption } from "../../../types/DropDown";
import { IFolderTreeOptions } from "../../files/folderTree";
interface Props {
  options: IDropDownOption[] | IFolderTreeOptions[] | any;
  defaultValue?: any;
  isMulti?: any;
  onChange?: (
    values:
      | IDropDownOption
      | IFolderTreeOptions
      | IDropDownOption[]
      | IFolderTreeOptions[]
      | any,
    label?: any
  ) => void;
  value?: IDropDownOption[] | IFolderTreeOptions[];
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
  mandatory?: boolean;
  label?: string;
  onBlur?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  deleteItem?: boolean;
  newTemplateCreated?: boolean;
  editedItem?: any;
  menuPlacement?: MenuPlacement;
  placeholder?: any;
  resetInput?: any;
  def?: any;
  wrapperStyle?: any;
  wrapperClassName?: string;
  drawerOpen?: boolean;
}
export default function DropDown(props: Props) {
  const [values, setValues] = useState<
    | IDropDownOption[]
    | IFolderTreeOptions[]
    | IDropDownOption
    | IFolderTreeOptions
    | any
  >([]);

  const [defaultValuesAreSet, setDefaultValuesAreSet] =
    useState<boolean>(false);

  const findOptionForValue = (value: any) => {
    for (const key in props.options) {
      const option = props.options[key];
      if (Array.isArray(value)) {
        if (
          value.some(
            (val) => JSON.stringify(val) === JSON.stringify(option.value)
          )
        ) {
          return option;
        }
      }
      if (value === option.value) {
        return option;
      }
    }
  };
  useEffect(() => {
    if (
      props.defaultValue === undefined ||
      props.options.length === 0 ||
      defaultValuesAreSet
    )
      return;

    const preSelectedValue: any = [];

    if (props.isMulti && Array.isArray(props.defaultValue)) {
      props.defaultValue.forEach((val) => {
        const option = findOptionForValue(val);
        if (option) {
          preSelectedValue.push(option);
        }
      });
    } else if (!Array.isArray(props.defaultValue)) {
      const option = findOptionForValue(props.defaultValue);
      if (option) {
        preSelectedValue.push(option);
      }
    }

    if (preSelectedValue.length > 0) {
      setValues(preSelectedValue);
      setDefaultValuesAreSet(true);
    }
  }, [props.defaultValue, props.options, defaultValuesAreSet]);

  useEffect(() => {
    if (props.onChange) {
      if (!props.isMulti && Array.isArray(values)) {
        props.onChange(values[0], props.name);
      } else {
        props.onChange(values, props.name);
      }
    }
  }, [values]);
  const onChangeHandler = (selectedOption: any, action?: any) => {
    setValues(selectedOption);
    if (action?.action === "clear" && props.resetInput) {
      props.resetInput();
    }
  };

  useEffect(() => {
    if (props.editedItem === true) {
      setDefaultValuesAreSet(false);
      setValues([]);
    }
  }, [props.editedItem]);

  useEffect(() => {
    if (props.deleteItem === true) {
      setValues([]);
    }
  }, [props.deleteItem]);

  return (
    <div
      className={props.wrapperClassName}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...props.wrapperStyle,
      }}
    >
      <label className={props.labelClassName} style={props.labelStyle}>
        {props.mandatory === true ? (
          <>
            {props.label}
            <span style={{ color: "red" }}>*</span>
          </>
        ) : (
          <>{props.label}</>
        )}
      </label>
      <Select
        id={props.name}
        options={props.options}
        value={values}
        onChange={(selectedOption, action) =>
          onChangeHandler(selectedOption, action)
        }
        onBlur={props.onBlur}
        name={props.name}
        isMulti={props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        required={props.required}
        isLoading={props.options.length !== 0 ? false : true}
        menuPlacement={props.menuPlacement}
        placeholder={props.placeholder}
        menuPortalTarget={document.body}
        styles={{
          clearIndicator: (base) => ({
            ...base,
            color: "#ba78de",
            backgroundColor: "#f4f2f2",
            borderRadius: "0.25rem",
            marginRight: "0.3rem",
            "&:hover": {
              color: "#8c1ec8",
            },
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: "#ba78de",
            backgroundColor: "#f4f2f2",
            borderRadius: "0.25rem",
            margin: "0 0.3rem",
            "&:hover": {
              color: "#8c1ec8",
            },
          }),
          loadingIndicator: (base) => ({
            ...base,
            color: "#ba78de",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            borderRadius: "0.25rem",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: "0",
            padding: "0",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0.75rem",
            borderRadius: "0.25rem",
          }),
          option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? "#f4f2f2"
                : isFocused
                ? "#a34bd3"
                : undefined,
              color: isDisabled
                ? undefined
                : isSelected
                ? "black"
                : isFocused
                ? "white"
                : undefined,

              cursor: isDisabled ? "not-allowed" : "default",
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: props.drawerOpen ? 99999 : 9999,
          }),

          multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#8c1ec8",
            borderRadius: "1.5rem",
            color: "white",
            padding: "0.25rem 0.5rem 0.25rem 0.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.25rem",
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: "white",
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            borderRadius: "50%",
            color: "#8c1ec8",
            backgroundColor: "white",
            width: "1.25rem",
            height: "1.25rem",
            padding: "0.025rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ":hover": {
              color: "#ff1744",
            },
          }),
        }}
      />
    </div>
  );
}
