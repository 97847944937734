import { useState, useEffect, useCallback, ReactNode } from "react";
import {
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Delete, Edit } from "@mui/icons-material";
import { Backdrop, Box, Tooltip, MenuItem, ListItemIcon } from "@mui/material";
import GenericCrudTableModal from "./genericCrudTableModal";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import CheckIcon from "@mui/icons-material/Check";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import GridViewIcon from "@mui/icons-material/GridView";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Department } from "../userAdministration/administration";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { useDispatch } from "react-redux";
import { setTableSearch } from "../state/tableSearch/tableSearchSlice";
import styles from "./genericCrudTable.module.css";
import GroupsIcon from "@mui/icons-material/Groups";
import React from "react";
import { euroToEurocent } from "../../helper/formattingValues";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";
interface Props {
  entity: string;
  customEditHandler?: (row: any) => void;
  customDeleteHandler?: (row: any) => void;
  customCreateHandler?: () => void;
  handleTimeslotEditHandler?: (row: any) => void;
  handleSendRegistrationEmail?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleUnblockUser?: (row: any, skipQuestion?: false | undefined) => void;
  handleShowAllMailsSentToUser?: (row: any) => void;
  uploadSuccessful?: boolean;
  createUser?: boolean;
  companySubjectId?: string;
  companyId?: string;
  columns: any;
  disallowDelete?: boolean;
  disallowEdit?: boolean;
  setAppointmentRequestStatusOpen?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleReinviteSubjects?: (row: any, skipQuestion?: false | undefined) => void;
  isUsedInMeasurement?: (row: any) => boolean | undefined;
  resetDemoCompany?: any;
  additionalUrlParameter?: any;
  additionalRoutePartsForGet?: any;
  additionalRoutePartsForDelete?: any;
  handleShowInvitedPersons?: (row: any) => void;
  handleBulkAddUsersToDepartments?: (selectedUsers: any[]) => void;
  handleMovePerson?: (row: any) => void;
  handleBulkMoveUsersToCompany?: (selectedUsers: any[]) => void;
  moveUserSuccessful?: boolean;
  handleUndeleteUser?: (row: any, reset: () => void) => void;
  searchId?: string;
  department?: Department[];
  hasDepartments?: any;
  heading?: string | any;
  withSubjectSearch?: boolean; // must be given from the table if a subjectSearch is used for calculation of height
  handleShowCoredata?: (row: any) => void;
  showSubjectOnCompany?: boolean;
  additionalColumnsForCreate?: any;
  additionalColumnsForEdit?: any;
  subjectIdForBuildings?: any;
  handleSetSubjectIdForBuildings?: any;
  handleGetMailTemplateId?: any;
  setMainBuildingOnManager?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  refreshTable?: boolean;
  readOnlyMode?: boolean;
  additionalComponentForModal?: ReactNode;
  customModalStyle?: any;
  expandedDepth?: boolean;
  customColumnVisibility?: any;
  customTopToolBarAction?: ReactNode | undefined;
  customTopToolBarActionButton?: ReactNode | undefined;
  customGlobalFilterPosition?: any;
  customDensity?: "spacious" | "compact" | "comfortable";
  customPageSize?: any;
  showRowActions?: boolean;
  customRowActions?: any;
  entityType?: any;
  tableCollapseWidth?: any;
  getOnlyUsersFor?: string;
  allowColumnFiltering?: boolean;
  showColumnFilterbyDefault?: boolean;
  customCreatePresetValues?: any;
  additionalSubmitDataForCreate?: any;
  additionalOnSuccessfulCreateFunction?: any;
  hideDeleteButtonForRows?: boolean;
  disableSorting?: boolean;
  disableGlobalFilter?: boolean;
  customRowActionsLayout?: "buttons" | "menu";
  customDetailPanel?: any;
  customTableData?: any;
  disableRowDelete?: boolean | undefined;
  disableRowEdit?: boolean | undefined;
  disableAddItem?: boolean;
  refreshTableAfterAction?: () => void;
  subdomain?: string;
  additionalKeyForParameteresToRender?: any;
  onSuccessfulGetHandler?: (data: any) => void;
}

export default function GenericCrudTable(props: Props) {
  const [tableRows, setTableRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>("insert");
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [successForNotification, setSuccessForNotification] =
    useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: props.customPageSize ? props.customPageSize : 50,
  });
  const [isRefetching, setIsRefetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnSorting, setColumnSorting] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [defaultSortingLoaded, setDefaultSortingLoaded] =
    useState<boolean>(false);
  const [showRefetchLoader, setShowRefetchLoader] = useState<boolean>(false);

  const { showConfirmationModalAsync } = useConfirmationModal();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedChildCompany: any = useSelector(
    (state: RootState) => state.parent
  );
  const tableSearchState = useSelector(
    (state: RootState) => state.tableSearch[props.entity]
  );

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const data = tableRows;
  const columns = props.columns;
  const table = useMaterialReactTable({
    data,
    columns,
    state: { columnFilters: columnFilter },
  });

  useEffect(() => {
    if (tableSearchState && tableSearchState !== undefined) {
      setGlobalFilter(tableSearchState);
    } else setGlobalFilter("");
  }, []);

  const resetStateOnCloseNotification = () => {
    setSuccessForNotification(false);
  };
  useEffect(() => {
    if (successForNotification) {
      const timer = setTimeout(() => {
        setSuccessForNotification(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successForNotification]);

  useEffect(() => {
    let descending; // conversion to boolean for tableState
    let sort; // sortingValue for URL
    let tableSort; // sortingValue for tableState
    switch (props.entity) {
      case "user":
        sort = "last_name";
        tableSort = "last_name";
        descending = false;
        break;
      case "department":
        sort = "id";
        tableSort = "id";
        descending = false;
        break;
      case "company":
        sort = "name";
        tableSort = "name";
        descending = false;
        break;
      case "permission":
        sort = "content_type";
        tableSort = "content_type";
        descending = false;
        break;
      case "group":
        sort = "name";
        tableSort = "name";
        descending = false;
        break;
      case "auditlog":
        sort = "-timestamp";
        tableSort = "timestamp";
        descending = false;
        break;
      default:
        sort = "-created_on";
        tableSort = "created_on";
        descending = true;
    }
    setColumnSorting(sort);
    setSorting([{ id: tableSort, desc: descending }]);
  }, []);

  useEffect(() => {
    if (sorting.length !== 0) {
      let id = sorting[0].id;
      if (id === "category" && props.entity === "appointmentRequest") {
        id = "category_name";
      }
      let descending = "";
      if (sorting[0].desc === true) {
        descending = "-";
      }
      setInitialLoading(false);
      setColumnSorting(`${descending}${id}`);
      setDefaultSortingLoaded(true);
      setShowRefetchLoader(true);
    } else if (sorting.length === 0) {
      setInitialLoading(false);
      setColumnSorting("-created_on");
      setDefaultSortingLoaded(true);
      setShowRefetchLoader(true);
    }
  }, [
    sorting,
    props.searchId,
    pagination,
    columnSorting,
    globalFilter,
    props.department,
    props.refreshTable,
    columnFilter,
    props.additionalUrlParameter,
  ]);

  const resetTable = function () {
    setTableRows([]);
  };

  const handleOpenEdit = (row: any) => {
    if (props.customEditHandler) {
      props.customEditHandler(row);
    } else {
      openEditModal(row);
    }
  };
  const handleOpenCreate = () => {
    if (props.customCreateHandler) {
      props.customCreateHandler();
    } else openCreateModal();
  };
  useEffect(() => {
    resetTable();
    setInitialLoading(false);
  }, [
    props.companySubjectId,
    props.uploadSuccessful,
    props.moveUserSuccessful,
  ]);

  useEffect(() => {
    setRowSelection({});
  }, [props.moveUserSuccessful]);

  const handleResetOnUndelete = () => {
    resetTable();
    setInitialLoading(false);
    setDefaultSortingLoaded(true);
  };

  useEffect(() => {
    if (
      (!initialLoading ||
        props.searchId ||
        props.searchId === "" ||
        props.department?.length !== 0) &&
      defaultSortingLoaded &&
      !props.customTableData
    ) {
      const fetchData = async () => {
        // if (tableRows.length === 0) {
        //   setIsLoading(true);
        // } else {
        //   setIsRefetching(true);
        // }

        /*eslint-disable */
        let additionalUrlParameters: any = {};

        if (props.companySubjectId) {
          additionalUrlParameters["impersonate_subject"] =
            props.companySubjectId;
          // "?impersonate_subject=" + props.companySubjectId;
        }
        if (props.searchId && props.searchId !== "") {
          switch (props.entity) {
            case "appointmentRequest":
              additionalUrlParameters["company"] = props.searchId;
              break;
            case "note":
              additionalUrlParameters["entity_id"] = props.searchId;
              break;
            default:
              additionalUrlParameters["subject"] = props.searchId;
          }
        }
        // departments set?
        if (
          props.department &&
          props.department.length !== 0 &&
          props.entity === "user"
        ) {
          additionalUrlParameters["department_id"] =
            props.department[0].departmentId;
        }

        if (props.showSubjectOnCompany) {
          additionalUrlParameters["subject"] = "true";
        }

        // additionalParameters set within columns?
        if (props.additionalUrlParameter) {
          for (const key in props.additionalUrlParameter) {
            additionalUrlParameters[key] = props.additionalUrlParameter[key];
          }
        }

        if (columnFilter.length !== 0) {
          for (const key in columnFilter) {
            const singleColumnValues: any = columnFilter[key];
            const column = singleColumnValues.id;
            let columnSearchValue = singleColumnValues.value;
            const columnDef: any = table.getColumn(column).columnDef;
            let customFilterId = "";
            let filterVariant = "";
            let customFilterParameters = "";
            if (columnDef?.customColumnFilterId) {
              customFilterId = columnDef?.customColumnFilterId;
            }
            if (columnDef?.filterVariant) {
              filterVariant = columnDef?.filterVariant;
            }
            if (columnDef?.customFilterParameter) {
              customFilterParameters = columnDef?.customFilterParameter;
            }

            if (filterVariant === "date") {
              const tempDateString = new Date(
                singleColumnValues.value
              ).toISOString();
              columnSearchValue = dayjs(tempDateString).format("YYYY-MM-DD");
              if (customFilterId !== "") {
                additionalUrlParameters[column + customFilterParameters] =
                  columnSearchValue;
              } else
                additionalUrlParameters[column + "__date"] = columnSearchValue;
            } else if (customFilterId !== "") {
              if (customFilterParameters) {
                additionalUrlParameters[
                  customFilterId + customFilterParameters
                ] = columnSearchValue;
              } else if (column === "price") {
                //custom for trackedServiceTables Price
                //replace and format to cent for columnsearch
                const formatedValue = euroToEurocent(
                  singleColumnValues.value.toString().replace(",", ".")
                );
                columnSearchValue = formatedValue;

                additionalUrlParameters[column] = columnSearchValue;
              } else
                additionalUrlParameters[customFilterId + "__icontains"] =
                  columnSearchValue;
            } else if (customFilterParameters) {
              additionalUrlParameters[column + "_icontains"] =
                columnSearchValue;
            } else
              additionalUrlParameters[column + "__icontains"] =
                columnSearchValue;
            if (
              props.entity === "appointmentRequest" &&
              column === "status" &&
              columnSearchValue === "completed"
            ) {
              additionalUrlParameters["include_completed"] = true;
            }
          }
        }
        const additionalKeyValuePair: any = {};
        if (props.additionalKeyForParameteresToRender) {
          additionalKeyValuePair[props.additionalKeyForParameteresToRender] =
            globalFilter;
        }

        try {
          let additionalRouteParts: any = {};
          if (props.additionalRoutePartsForGet) {
            additionalRouteParts = props.additionalRoutePartsForGet;
          }

          const res = await api.genericApiRequest({
            method: "get",
            entity: props.entity,
            additionalRouteParts: additionalRouteParts,
            parametersToRender: {
              depth: props.expandedDepth ? "1" : "0",
              ordering: columnSorting,
              limit: pagination.pageSize,
              offset: pagination.pageIndex * pagination.pageSize,
              search: !props.additionalKeyForParameteresToRender
                ? globalFilter
                : "",
              additionalUrlParameters: additionalUrlParameters,
              ...additionalKeyValuePair,
            },

            successHandler: (res: any) => {
              /*eslint-enable */

              const data = res.data;

              setTableRows(data.results);
              setRowCount(data.count);
              setIsLoading(false);
              setInitialLoading(true);
              setDefaultSortingLoaded(false);
              setShowRefetchLoader(false);
              // setIsRefetching(false);
              if (props.onSuccessfulGetHandler) {
                props.onSuccessfulGetHandler(data);
              }

              if (
                props.entity === "department" &&
                props.hasDepartments &&
                res.data.results.length === 0
              ) {
                props.hasDepartments();
              }
              if (props.entity === "user") {
                const allEmails = [];
                for (const key in res.data.results) {
                  const item = res.data.results[key];
                  const email = item.email;
                  allEmails.push(email);
                }
                setUserEmails(allEmails);
              }
            },
          });
        } catch (error) {
          setError(error);
          return;
        }
        setIsRefetching(false);
        // setDefaultSortingLoaded(false);
      };
      fetchData();
    } else if (props.customTableData) {
      setTableRows(props.customTableData);
      setIsLoading(false);
      setInitialLoading(true);
      setDefaultSortingLoaded(false);
      setShowRefetchLoader(false);
    }
  }, [
    tableRows.length,
    props.entity,
    initialLoading,
    columnSorting,
    pagination.pageSize,
    pagination.pageIndex,
    globalFilter,
    props.searchId,
    defaultSortingLoaded,
    props.department,
    columnFilter,
  ]);

  const handleDeleteRow = useCallback(
    (row: any, skipQuestion = false) => {
      const deleteRow = async () => {
        if (!skipQuestion) {
          const isConfirmed = await showConfirmationModalAsync(
            t("delete_entry_question"),
            {
              confirmButtonText: t("delete"),
            }
          );
          if (!isConfirmed) {
            return;
          }
        }
        row.toggleSelected(false);

        const additionalUrlParameters: any = {};
        const additionalRouteParts: any = {};

        const id = row.getValue("id");

        if (props.companySubjectId) {
          additionalUrlParameters["impersonate_subject"] =
            props.companySubjectId;
        }

        const fetchData = async () => {
          try {
            /*eslint-disable */
            const res = await api.genericApiRequest({
              method: "delete",
              entity: props.entity,
              entityId: id,
              parametersToRender: {
                additionalUrlParameters: additionalUrlParameters,
                depth: "0",
              },
              additionalRouteParts: additionalRouteParts,
              successHandler: () => {
                /*eslint-enable */
                setNotificationMessage(t("delete_successful"));
                setNotificationVariant("success");
                setSuccessForNotification(true);
                resetTable();
                setInitialLoading(false);
                setDefaultSortingLoaded(true);
                if (props.refreshTableAfterAction) {
                  props.refreshTableAfterAction();
                }
              },
            });
          } catch (error: any) {
            setError(error);
          }
        };

        await fetchData();
      };
      deleteRow();
    },
    [props.entity, showConfirmationModalAsync]
  );

  const rowActionOnClickHandlers = (row: any, closeMenu?: () => void) => ({
    editRowActionHandler: () => {
      handleOpenEdit(row);
      if (props.handleSetSubjectIdForBuildings) {
        props.handleSetSubjectIdForBuildings?.(row.original);
      }
      if (props.handleGetMailTemplateId) {
        props.handleGetMailTemplateId?.(row.original);
      }
      if (closeMenu) closeMenu();
    },

    deleteRowActionHandler: () => {
      props.customDeleteHandler
        ? props.customDeleteHandler(row)
        : handleDeleteRow(row);
      if (closeMenu) closeMenu();
    },

    undeleteRowActionHandler: () => {
      props.handleUndeleteUser?.(row, handleResetOnUndelete);
      if (closeMenu) closeMenu();
    },

    unblockUserRowActionHandler: () => {
      props.handleUnblockUser?.(row);
      if (closeMenu) closeMenu();
    },

    showAllMailsRowActionHandler: () => {
      props.handleShowAllMailsSentToUser?.(row);
      if (closeMenu) closeMenu();
    },

    movePersonRowActionHandler: () => {
      props.handleMovePerson?.(row);
      if (closeMenu) closeMenu();
    },

    setMainBuildingRowActionHandler: () => {
      props.setMainBuildingOnManager?.(row.original);
      if (closeMenu) closeMenu();
    },

    sendRegistrationEmailRowActionHandler: () => {
      props.handleSendRegistrationEmail?.(row);
      if (closeMenu) closeMenu();
    },

    statusChangeRowActionHandler: () => {
      row.original.status === "draft"
        ? props?.setAppointmentRequestStatusOpen?.(row)
        : props?.handleReinviteSubjects?.(row);
      if (closeMenu) closeMenu();
    },

    showInvitedPersonsRowActionHandler: () => {
      props.handleShowInvitedPersons?.(row);
      if (closeMenu) closeMenu();
    },

    editTiimeslotRowActionHandler: () => {
      props.handleTimeslotEditHandler?.(row.original.id);
      if (closeMenu) closeMenu();
    },
  });

  const getRowActions = (row: any, closeMenu?: () => void) => {
    const {
      editRowActionHandler,
      deleteRowActionHandler,
      undeleteRowActionHandler,
      unblockUserRowActionHandler,
      showAllMailsRowActionHandler,
      movePersonRowActionHandler,
      setMainBuildingRowActionHandler,
      sendRegistrationEmailRowActionHandler,
      statusChangeRowActionHandler,
      showInvitedPersonsRowActionHandler,
      editTiimeslotRowActionHandler,
    } = rowActionOnClickHandlers(row, closeMenu);

    const isMenu = props.customRowActionsLayout === "menu";

    const editRowAction = isMenu ? (
      <MenuItem
        key={0}
        onClick={editRowActionHandler}
        disabled={!isEditAllowed()}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        {t("edit")}
      </MenuItem>
    ) : (
      <Tooltip key={0} arrow placement="left" title={t("edit")}>
        <span>
          <GenericButton
            variant="icon"
            color="iconBase"
            onClick={editRowActionHandler}
            disabled={!isEditAllowed()}
          >
            <Edit />
          </GenericButton>
        </span>
      </Tooltip>
    );

    const deleteRowAction = isMenu ? (
      <MenuItem
        key={1}
        onClick={deleteRowActionHandler}
        disabled={isDeletionAllowed(row)}
      >
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        {t("delete")}
      </MenuItem>
    ) : (
      <Tooltip key={1} arrow placement="right" title={t("delete")}>
        <span>
          <GenericButton
            disabled={isDeletionAllowed(row)}
            color="error"
            variant="icon"
            onClick={deleteRowActionHandler}
          >
            <Delete />
          </GenericButton>
        </span>
      </Tooltip>
    );

    const undeleteRowAction = isMenu ? (
      <MenuItem key={2} onClick={undeleteRowActionHandler}>
        <ListItemIcon>
          <RestoreFromTrashIcon />
        </ListItemIcon>
        {t("undelete", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      </MenuItem>
    ) : (
      <Tooltip
        key={2}
        arrow
        placement="right"
        title={t("undelete", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={undeleteRowActionHandler}
        >
          <RestoreFromTrashIcon />
        </GenericButton>
      </Tooltip>
    );

    const unblockUserRowAction = isMenu ? (
      <MenuItem key={3} onClick={unblockUserRowActionHandler}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        {t("unblock_user")}
      </MenuItem>
    ) : (
      <Tooltip key={3} arrow placement="right" title={t("unblock_user")}>
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={unblockUserRowActionHandler}
        >
          <LockOpenIcon />
        </GenericButton>
      </Tooltip>
    );

    const showMailRowAction = isMenu ? (
      <MenuItem key={4} onClick={showAllMailsRowActionHandler}>
        <ListItemIcon>
          <AllInboxIcon />
        </ListItemIcon>
        {t("show_all_mails_sent_to_user", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      </MenuItem>
    ) : (
      <Tooltip
        key={4}
        arrow
        placement="right"
        title={t("show_all_mails_sent_to_user", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={showAllMailsRowActionHandler}
        >
          <AllInboxIcon />
        </GenericButton>
      </Tooltip>
    );

    const movePersonRowAction = isMenu ? (
      <MenuItem key={5} onClick={movePersonRowActionHandler}>
        <ListItemIcon>
          <PersonRemoveIcon />
        </ListItemIcon>
        {t("move_customer_to_company", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      </MenuItem>
    ) : (
      <Tooltip
        key={5}
        arrow
        placement="right"
        title={t("move_customer_to_company", {
          user: row.original.first_name + " " + row.original.last_name,
        })}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={movePersonRowActionHandler}
        >
          <PersonRemoveIcon />
        </GenericButton>
      </Tooltip>
    );

    const setMainBuildingRowAction = isMenu ? (
      <MenuItem key={6} onClick={setMainBuildingRowActionHandler}>
        <ListItemIcon>
          <MapsHomeWorkIcon />
        </ListItemIcon>
        {t("building_set_main_building", {
          building: row.original.name,
        })}
      </MenuItem>
    ) : (
      <Tooltip
        key={6}
        arrow
        placement="right"
        title={t("building_set_main_building", {
          building: row.original.name,
        })}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={setMainBuildingRowActionHandler}
        >
          <MapsHomeWorkIcon />
        </GenericButton>
      </Tooltip>
    );

    const sendRegistrationEmailRowAction = isMenu ? (
      <MenuItem key={7} onClick={sendRegistrationEmailRowActionHandler}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        {t("send_registration_email")}
      </MenuItem>
    ) : (
      <Tooltip
        key={7}
        arrow
        placement="right"
        title={t("send_registration_email")}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={sendRegistrationEmailRowActionHandler}
        >
          <EmailIcon />
        </GenericButton>
      </Tooltip>
    );

    const statusChangeRowAction = isMenu ? (
      <MenuItem
        key={8}
        onClick={statusChangeRowActionHandler}
        disabled={
          row.original.status === "draft" &&
          row?.original?.day_set?.length === 0
        }
      >
        <ListItemIcon>
          {row.original.status === "draft" ? <CheckIcon /> : <GroupAddIcon />}
        </ListItemIcon>
        {row.original.status === "draft" && row?.original?.day_set?.length === 0
          ? t("appointmentRequest_day_required")
          : row.original.status === "draft"
          ? t("status_change")
          : t("reinvite_subjects")}
      </MenuItem>
    ) : (
      <Tooltip
        key={8}
        arrow
        placement="right"
        title={
          row.original.status === "draft" &&
          row?.original?.day_set?.length !== 0
            ? t("status_change")
            : row.original.status === "draft" &&
              row?.original?.day_set?.length === 0
            ? t("appointmentRequest_day_required")
            : t("reinvite_subjects")
        }
      >
        <span>
          <GenericButton
            variant="icon"
            color="iconBase"
            disabled={
              row.original.status === "draft" &&
              row?.original?.day_set?.length === 0
            }
            onClick={statusChangeRowActionHandler}
          >
            {row.original.status === "draft" ? (
              <CheckIcon />
            ) : row.original.status === "open" ? (
              <GroupAddIcon />
            ) : null}
          </GenericButton>
        </span>
      </Tooltip>
    );

    const showInvitedPersonsRowAction = isMenu ? (
      <MenuItem key={9} onClick={showInvitedPersonsRowActionHandler}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        {t("show_invited")}
      </MenuItem>
    ) : (
      <Tooltip key={9} arrow placement="right" title={t("show_invited")}>
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={showInvitedPersonsRowActionHandler}
        >
          <PeopleIcon />
        </GenericButton>
      </Tooltip>
    );

    const editTimeslotsRowAction = isMenu ? (
      <MenuItem key={10} onClick={editTiimeslotRowActionHandler}>
        <ListItemIcon>
          <GridViewIcon />
        </ListItemIcon>
        {t("edit_available_timeslots")}
      </MenuItem>
    ) : (
      <Tooltip
        key={10}
        arrow
        placement="right"
        title={t("edit_available_timeslots")}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={editTiimeslotRowActionHandler}
        >
          <GridViewIcon />
        </GenericButton>
      </Tooltip>
    );

    const rowActions = [
      checkForShowRowEdit(row) && editRowAction,
      checkForShowRowDelete(row) && deleteRowAction,
    ];

    if (
      props.entity === "user" &&
      props.companySubjectId &&
      row.original.is_deleted
    ) {
      rowActions.push(undeleteRowAction);
    } else {
      if (props.entity === "appointmentRequest") {
        if (row.original.status === "draft" || row.original.status === "open") {
          rowActions.push(statusChangeRowAction);
        }

        if (row.original.status === "open") {
          rowActions.push(showInvitedPersonsRowAction, editTimeslotsRowAction);
        }
      }

      if (props.entity === "user") {
        rowActions.push(sendRegistrationEmailRowAction);

        if (props.companySubjectId) {
          if (row.original.locked_out) {
            rowActions.push(unblockUserRowAction);
          }
          rowActions.push(showMailRowAction, movePersonRowAction);
        }
      }

      if (
        props.entity === "building" &&
        !props.companySubjectId &&
        !row.original.is_main_building
      ) {
        rowActions.push(setMainBuildingRowAction);
      }

      if (props.customRowActions) {
        rowActions.push(props.customRowActions(row));
      }
    }

    return rowActions;
  };

  const renderRowActionsAsButtons = (row: any) => {
    const actions = getRowActions(row).map((action, index) => (
      <React.Fragment key={index}>{action}</React.Fragment>
    ));

    return <Box className={styles.rowActionsWrapper}>{actions}</Box>;
  };

  const renderRowActionsAsMenu = (row: any, closeMenu: () => void) => {
    const actions = getRowActions(row, closeMenu).map((action) => action);
    return actions;
  };

  const sanitizeValuesBeforeSendingToApi = (values: any) => {
    //Check each value for it's column config. If it's configured with "isJson = True" and if the value is a string, then convert it to a full JSON object before saving
    if (values && typeof values == "object") {
      for (const [key, value] of Object.entries(values)) {
        for (const columnKey in props.columns) {
          const column = props.columns[columnKey];
          if (column.accessorKey === key) {
            if (
              column.isJson === true &&
              (typeof value === "string" || value instanceof String)
            ) {
              values[key] = JSON.parse(values[key]);
            }
          }
        }
      }
    }
    return values;
  };
  const handleCreateNewRow = async (
    values: any,
    resetFunction?: () => void
  ) => {
    let result = sanitizeValuesBeforeSendingToApi(values);

    if (
      (selectedChildCompany.subjectId || props.companySubjectId) &&
      props.entity === "department"
    ) {
      result = {
        ...values,
        company: props.companyId,
        impersonate_subject: selectedChildCompany.subjectId
          ? selectedChildCompany.subjectId
          : props.companySubjectId,
      };
    }

    //Add user on Admin
    if (props.companySubjectId && props.entity === "user") {
      result = {
        ...values,
        impersonate_subject: props.companySubjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
        team_id: values.team,
      };
    }
    //Add user on Manager
    if (
      !props.companySubjectId &&
      !props.companyId &&
      props.entity === "user"
    ) {
      result = {
        ...values,
        impersonate_subject: selectedChildCompany.subjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
      };
    }
    if (props.entity === "company") {
      result = {
        ...values,
        team_id: values.team,
        main_building: {
          name: values.building_name,
          zip: values.zip,
          street_house: values.street_house,
          city: values.city,
          contact_name: values.contact_name,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
        },
      };
    }
    if (
      props.entity === "building" &&
      props.companySubjectId &&
      props.companySubjectId !== undefined
    ) {
      result = { ...values, subject: props.companySubjectId };
    }

    if (props.entity === "note") {
      result = {
        payload: values.payload === undefined ? "" : values.payload,
        entity_id: props.searchId,
        entity_type: props.entityType,
      };
    }

    if (props.additionalSubmitDataForCreate) {
      for (const [key, value] of Object.entries(
        props.additionalSubmitDataForCreate
      )) {
        result[key] = value;
      }
    }
    if (props.entity === "user" && result.is_analog === "true") {
      if (result.email === undefined) {
        delete result.email;
        result = { ...result };
      }
    }

    if (props.entity === "todo" && result.staff === true) {
      delete values.subject;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // eslint-disable-next-line
      const response = await new Promise<any>((resolve, reject) => {
        api.genericApiRequest({
          method: "post",
          entity: props.createUser ? "user/create_worker" : props.entity,
          successHandler: (res: any) => {
            resolve(res);
          },
          failHandler: (error: any) => {
            reject(error);
          },
          submitData: result,
          config: config,
        });
      });
      setNotificationMessage(t("create_successful"));
      setNotificationVariant("success");
      setSuccessForNotification(true);
      setModalPresetValues([]);
      resetTable();
      setInitialLoading(false);
      setDefaultSortingLoaded(true);
      setModalOpen(false);
      if (resetFunction) {
        resetFunction();
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleSaveRow = async (values: any, resetFunction?: () => void) => {
    let result = sanitizeValuesBeforeSendingToApi(values);

    if (
      (selectedChildCompany.subjectId || props.companySubjectId) &&
      props.entity === "department"
    ) {
      result = {
        ...values,
        company: props.companyId,
        impersonate_subject: selectedChildCompany.subjectId
          ? selectedChildCompany.subjectId
          : props.companySubjectId,
      };
    }
    if (props.entity === "permission") {
      result = {
        ...values,
        codename: modalPresetValues.codename,
        content_type: modalPresetValues.content_type,
      };
    }

    //Edit user on admin
    if (props.companySubjectId && props.entity === "user") {
      result = {
        ...values,
        company_id: props.companySubjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
        team_id: values.team,
      };
    }
    //Edit user on manager
    if (
      !props.companySubjectId &&
      !props.companyId &&
      props.entity === "user"
    ) {
      result = {
        ...values,
        impersonate_subject: selectedChildCompany.subjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
      };
    }
    if (props.entity === "company") {
      result = {
        ...values,
        parent: values.parent === undefined ? null : values.parent,
        team_id: values.team,
      };
    }
    if (props.entity === "note") {
      result = {
        payload: values.payload === undefined ? "" : values.payload,
        entity_id: props.searchId,
        entity_type: props.entityType,
      };
    }
    if (props.entity === "user" && result.is_analog === "true") {
      if (result.email === undefined) {
        delete result.email;
        result = { ...result };
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await new Promise<any>((resolve, reject) => {
        api.genericApiRequest({
          entity: props.entity,
          method: "put",
          successHandler: (res: any) => {
            resolve(res);
          },
          failHandler: (error: any) => {
            reject(error);
          },

          entityId: String(modalPresetValues.id),
          submitData: result,
          config: config,
        });
      });

      setNotificationMessage(t("save_successful"));
      setNotificationVariant("success");
      setSuccessForNotification(true);
      setModalPresetValues([]);
      resetTable();
      setInitialLoading(false);
      setDefaultSortingLoaded(true);
      setModalOpen(false);
      if (resetFunction) {
        resetFunction();
      }
      if (props.additionalOnSuccessfulCreateFunction) {
        props.additionalOnSuccessfulCreateFunction(response.data);
      }
      if (props.refreshTableAfterAction) {
        props.refreshTableAfterAction();
      }
    } catch (error) {
      setError(error);
    }
  };

  const openCreateModal = () => {
    setModalMode("insert");
    if (props.customCreatePresetValues) {
      setModalPresetValues(props.customCreatePresetValues);
    } else {
      setModalPresetValues([]);
    }
    setModalOpen(true);
  };

  const openEditModal = (row: any) => {
    const tempPresetValues = row.original;

    // if the entity to edit has childEntities, check if we need to get properties of this child one lvl up
    for (const columnKey in props.columns) {
      const column = props.columns[columnKey];
      if (column.childEntityName) {
        tempPresetValues[column.id] =
          tempPresetValues[column.childEntityName][column.id];
      }
    }
    setModalPresetValues(tempPresetValues);
    setModalMode("edit");
    setModalOpen(true);
  };

  const columnVisibility = {
    id: false,
    created_by: false,
    modified_by: false,
    modified_on: false,
  };
  if (props.entity === "measurementDefinition")
    Object.assign(columnVisibility, { type: false, unit: false, reuse: false });
  if (props.customColumnVisibility) {
    Object.assign(columnVisibility, props.customColumnVisibility);
  }

  const isDeletionAllowed = (row: any) => {
    //for disableing DeleteButton
    if (
      (props.disallowDelete && props.disallowDelete === true) ||
      props.isUsedInMeasurement?.(row) ||
      row.original.can_be_deleted === false
    ) {
      return true;
    }

    return false;
  };

  const isEditAllowed = () => {
    //for disableing Button
    // if (props.entity === "subjectExaminationDeadline") {
    //   if (
    //     row.original.status !== "valid" &&
    //     row.original.status !== "overdue"
    //   ) {
    //     return false;
    //   }
    // }

    return true;
  };

  function checkForShowRowEdit(row: any) {
    if (props.entity === "subjectExaminationDeadline") {
      if (
        row.original.status !== "valid" &&
        row.original.status !== "overdue"
      ) {
        return false;
      }
    }
    if (props.entity === "trackedService") {
      if (row.original.checked_on != null || row.original.billed_on != null) {
        return false;
      }
    }
    if (props.entity === "actionChainProgress") {
      return false;
    }
    return true;
  }

  function checkForShowRowDelete(row: any) {
    if (props.entity === "subjectExaminationDeadline") {
      if (
        row.original.status !== "valid" &&
        row.original.status !== "overdue"
      ) {
        return false;
      }
    }
    if (props.entity === "trackedService") {
      if (row.original.checked_on != null || row.original.billed_on != null) {
        return false;
      }
    }
    if (props.entity === "actionChainProgress") {
      return false;
    }
    return true;
  }

  const checkForManagerRole = (value: any) => {
    if (value.find((item: any) => item.name === "manager")) {
      return true;
    } else return false;
  };

  let enableEditing = true;
  let enableRowActions = true;

  if (
    (props.readOnlyMode && props.readOnlyMode == true) ||
    props.showRowActions === false
  ) {
    enableEditing = false;
    enableRowActions = false;
  }

  if (props.customRowActionsLayout === "menu") {
    enableEditing = false;
  }

  const handleSetGlobalTableSearch = (searchValue: string, entity: string) => {
    dispatch(setTableSearch({ searchValue: searchValue, entity: entity }));
  };

  const handleColumnFilter = (searchValues: any) => {
    setColumnFilter(searchValues);
  };

  let enableSorting = true;
  if (props.disableSorting) {
    enableSorting = false;
  }
  let enableGlobalFilter = true;
  if (props.disableGlobalFilter) {
    enableGlobalFilter = false;
  }

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <MaterialReactTable
          columns={props.columns}
          data={tableRows}
          editDisplayMode="modal"
          localization={MRT_Localization_DE}
          enableSorting={enableSorting}
          // enableStickyFooter={true}
          enableStickyHeader={true}
          layoutMode="semantic"
          displayColumnDefOptions={{
            "mrt-row-actions": {
              visibleInShowHideMenu: false,
              size: 40,
              muiTableHeadCellProps: {
                align: "center",
                sx: {
                  padding: "0.5rem",
                },
              },
              muiTableBodyCellProps: {
                sx: {
                  padding: "0.5rem",
                  textAlign: "center",
                  borderRight: "1px solid #e0e0e0",
                  "& .MuiIconButton-root": {
                    margin: 0,
                  },
                },
              },
            },
            "mrt-row-select": {
              visibleInShowHideMenu: false,
              size: 40,
              muiTableHeadCellProps: {
                align: "center",
                sx: {
                  padding: "0.5rem",
                },
              },
              muiTableBodyCellProps: {
                sx: {
                  padding: "0.5rem",
                  textAlign: "center",
                  borderRight: "1px solid #e0e0e0",
                  "& .MuiIconButton-root": {
                    margin: 0,
                  },
                },
              },
            },
            "mrt-row-expand": { visibleInShowHideMenu: false },
          }}
          positionGlobalFilter={
            props.customGlobalFilterPosition && props.customGlobalFilterPosition
            // : "right"
          }
          muiTableContainerProps={{
            sx: {
              maxHeight: props.withSubjectSearch
                ? `clamp(350px, calc(90vh - 16.5rem), 9999px)`
                : `clamp(350px, calc(90vh - 10.5rem), 9999px)`,
            },
          }}
          enableEditing={enableEditing}
          enableHiding
          enableColumnResizing={true}
          enableFullScreenToggle={false}
          enableRowActions={enableRowActions}
          manualFiltering={true}
          enableGlobalFilter={enableGlobalFilter}
          onGlobalFilterChange={(e: any) => {
            setGlobalFilter(e);
            setInitialLoading(false);
            handleSetGlobalTableSearch(e, props.entity);
          }}
          onColumnFiltersChange={(e: any) => {
            // setColumnFilter(e);
            handleColumnFilter(e);
          }}
          manualPagination={true}
          manualSorting={true}
          enableColumnOrdering={false}
          positionToolbarDropZone="none"
          onPaginationChange={(e: any) => {
            setPagination(e);
            setInitialLoading(false);
          }}
          rowCount={rowCount}
          onSortingChange={(e) => {
            setSorting(e), setInitialLoading(false);
          }}
          enableColumnFilters={
            subdomain === "admin" || subdomain === "manage"
              ? props.allowColumnFiltering
              : false
          }
          muiFilterTextFieldProps={
            {
              // onClick: () => {
              //   setColumnFilter([]);
              // },
              // placeholder: "",
            }
          }
          muiSearchTextFieldProps={{
            onClick: () => {
              setGlobalFilter("");
            },
            placeholder:
              props.entity === "subjectExaminationDeadline"
                ? `${t("examination")}...`
                : `${t("search_placeholder")}`,
            autoFocus:
              props.entity === "appointmentRequest" ||
              props.entity === "appointment" ||
              props.entity === "user" ||
              props.entity === "todo" ||
              props.entity === "note"
                ? false
                : true,
          }}
          muiTableBodyRowProps={({ row }: any) =>
            row.index % 2 === 0
              ? {
                  sx: {
                    backgroundColor: "#f5f5f5",
                  },
                }
              : { sx: { backgroundColor: "#FFF" } }
          }
          muiTablePaperProps={{
            sx: {
              marginBottom: "2rem",
              "& .MuiTextField-root": {
                backgroundColor: "#f4f2f2",
              },
              "&&  .MuiCollapse-root, && .MuiCollapse-wrapperInner": {
                width: props.tableCollapseWidth && props.tableCollapseWidth,
              },
              "&& .MuiTableCell-root": {
                whiteSpace: props.entity === "note" ? "break-spaces" : "",
              },
              // "&& .MuiSvgIcon-root": { fill: "#8c1ec8" },
              "&& .MuiTextField-root": {
                backgroundColor: "white",
              },
              "&& .MuiBox-root": {
                zIndex: "unset",
              },
            },
          }}
          muiTableBodyCellProps={({ row }: any) =>
            //Manager Bold on delete and active disabled for now

            props.entity === "user" &&
            checkForManagerRole(row.original.group_set) &&
            row.original.is_deleted === false
              ? {
                  sx: {
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    borderRight: "1px solid #e0e0e0",
                  },
                }
              : props.entity === "user" &&
                checkForManagerRole(row.original.group_set) &&
                row.original.is_deleted === true
              ? {
                  sx: {
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    borderRight: "1px solid #e0e0e0",
                    color: "lightgray",
                  },
                }
              : props.entity === "user" &&
                checkForManagerRole(row.original.group_set) &&
                row.original.is_deleted === true
              ? {
                  sx: {
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    borderRight: "1px solid #e0e0e0",
                    color: "lightgray",
                  },
                }
              : props.entity === "user" && row.original.is_deleted === true
              ? {
                  sx: {
                    borderRight: "1px solid #e0e0e0",
                    color: "lightgray",
                  },
                }
              : { sx: { borderRight: "1px solid #e0e0e0" } }
          }
          initialState={{
            columnVisibility: columnVisibility,
            showGlobalFilter: true,
            showColumnFilters:
              subdomain === "admin" || subdomain === "manage"
                ? props.showColumnFilterbyDefault
                : false,
            density: props.customDensity ? props.customDensity : "comfortable",
          }}
          state={{
            isLoading: isLoading,
            showProgressBars: isRefetching,
            pagination: pagination,
            globalFilter: globalFilter,
            columnFilters: columnFilter,
            sorting: sorting,
            rowSelection: rowSelection,
          }}
          renderRowActions={
            !props.customRowActionsLayout ||
            props.customRowActionsLayout === "buttons"
              ? ({ row }: any) => renderRowActionsAsButtons(row)
              : undefined
          }
          renderRowActionMenuItems={
            props.customRowActionsLayout === "menu"
              ? ({ row, closeMenu }: any) =>
                  renderRowActionsAsMenu(row, closeMenu)
              : undefined
          }
          enableRowSelection={props.entity === "user" ? true : false}
          onRowSelectionChange={(e: any) => {
            setRowSelection(e);
          }}
          enablePagination={props.customTableData ? false : true}
          onEditingRowSave={handleSaveRow}
          renderTopToolbarCustomActions={({ table }) => {
            const selectedUsers = table
              .getSelectedRowModel()
              .flatRows.map((row) => row.original);
            const numberOfSelectedUsers = selectedUsers.length;
            const selectedUserEmails = selectedUsers
              .map((user) => user.email)
              .join(", ");
            const selectedUserNames = selectedUsers
              .map((user) => user.first_name + " " + user.last_name)
              .join(", ");
            let confirmationMessage;

            const handleBulkAction = async (
              actionType: string,
              selectedUsers: any[]
            ) => {
              const promises = selectedUsers.map(async (user) => {
                switch (actionType) {
                  case "deletePeople":
                    return handleDeletePeople(user);
                  case "undeletePeople":
                    return handleUndeletePeople(user);
                  case "bulkSendRegistrationEmails":
                    return handleBulkSendRegistrationEmails(user);
                  case "bulkAddUsersToDepartments":
                    if (props.handleBulkAddUsersToDepartments) {
                      props.handleBulkAddUsersToDepartments(selectedUsers);
                      break;
                    } else {
                      return Promise.reject(
                        "handleBulkAddUsersToDepartments undefined"
                      );
                    }
                  case "bulkMoveUsersToCompany":
                    if (props.handleBulkMoveUsersToCompany) {
                      props.handleBulkMoveUsersToCompany(selectedUsers);
                      break;
                    } else {
                      return Promise.reject(
                        "handleBulkMoveUsersToCompany undefined"
                      );
                    }
                  default:
                    return Promise.reject(t("unknown_bulk_action"));
                }
              });

              try {
                setIsLoading(true);
                await Promise.all(promises);
                setIsLoading(false);
              } catch (error) {
                setError(error);
              }
            };

            let bulkDeleteConfirmationShown = false;
            let bulkDeleteOperationCancelled = false;
            const handleDeletePeople = (user: any, skipQuestion = false) => {
              return new Promise<void>((resolve, reject) => {
                if (!skipQuestion && !bulkDeleteConfirmationShown) {
                  if (bulkDeleteOperationCancelled) {
                    return;
                  }

                  confirmationMessage =
                    selectedUsers.length < 10
                      ? `${t("bulk_delete_entries_question", {
                          numberOfSelectedUsers,
                        })} (${selectedUserNames})`
                      : t("bulk_delete_many_entries_question", {
                          numberOfSelectedUsers,
                        });

                  if (
                    /*eslint-disable */
                    !confirm(confirmationMessage)
                    /*eslint-enable */
                  ) {
                    bulkDeleteOperationCancelled = true;
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    return;
                  }
                  bulkDeleteConfirmationShown = true;
                }

                const additionalUrlParameters: any = {};
                if (props.companySubjectId) {
                  additionalUrlParameters["impersonate_subject"] =
                    props.companySubjectId;
                }

                api.genericApiRequest({
                  method: "delete",
                  entity: "user",
                  entityId: user.id,
                  parametersToRender: {
                    additionalUrlParameters: additionalUrlParameters,
                    depth: "0",
                  },
                  successHandler: () => {
                    setNotificationMessage(t("delete_successful"));
                    setNotificationVariant("success");
                    setSuccessForNotification(true);
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    resolve();
                    bulkDeleteConfirmationShown = false;
                    setRowSelection({});
                    if (props.refreshTableAfterAction) {
                      props.refreshTableAfterAction();
                    }
                  },
                  failHandler: (error: any) => {
                    reject(error);
                  },
                });
              });
            };

            let bulkUndeleteConfirmationShown = false;
            let bulkUndeleteOperationCancelled = false;
            const handleUndeletePeople = (user: any, skipQuestion = false) => {
              return new Promise<void>((resolve, reject) => {
                if (!skipQuestion && !bulkUndeleteConfirmationShown) {
                  if (bulkUndeleteOperationCancelled) {
                    return;
                  }

                  confirmationMessage =
                    selectedUsers.length < 10
                      ? `${t("bulk_undelete_entries_question", {
                          numberOfSelectedUsers,
                        })} (${selectedUserNames})`
                      : t("bulk_undelete_many_entries_question", {
                          numberOfSelectedUsers,
                        });

                  if (
                    /*eslint-disable */
                    !confirm(confirmationMessage)
                  ) {
                    bulkUndeleteOperationCancelled = true;
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    return;
                  }
                  bulkUndeleteConfirmationShown = true;
                }

                const result = { is_active: true, email: user.email };
                api.genericApiRequest({
                  entity: "user",
                  entityId: user.id,
                  method: "put",
                  submitData: result,
                  successHandler: () => {
                    setNotificationMessage(t("user_successfully_undeleted"));
                    setNotificationVariant("success");
                    setSuccessForNotification(true);
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    resolve();
                    bulkUndeleteConfirmationShown = false;
                    setRowSelection({});
                  },
                  failHandler: (error: any) => {
                    reject(error);
                  },
                });
              });
            };

            let bulkSendRegistrationConfirmationEmailShown = false;
            let bulkSendRegistrationOperationCancelled = false;
            const handleBulkSendRegistrationEmails = (
              user: any,
              skipQuestion = false
            ) => {
              return new Promise<void>((resolve, reject) => {
                if (
                  !skipQuestion &&
                  !bulkSendRegistrationConfirmationEmailShown
                ) {
                  if (bulkSendRegistrationOperationCancelled) {
                    return;
                  }

                  if (selectedUsers.length < 10) {
                    confirmationMessage = user.is_registered
                      ? `${t("bulk_send_passwordreset_email_question", {
                          numberOfSelectedUsers,
                        })} (${selectedUserEmails})`
                      : `${t("bulk_send_registration_email_question", {
                          numberOfSelectedUsers,
                        })} (${selectedUserEmails})`;
                  } else {
                    confirmationMessage = user.is_registered
                      ? t(
                          "bulk_send_passwordreset_email_question_to_many_users",
                          {
                            numberOfSelectedUsers,
                          }
                        )
                      : t(
                          "bulk_send_registration_email_question_to_many_users",
                          {
                            numberOfSelectedUsers,
                          }
                        );
                  }

                  if (
                    /*eslint-disable */
                    !confirm(confirmationMessage)
                  ) {
                    bulkSendRegistrationOperationCancelled = true;
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    return;
                  }
                  bulkSendRegistrationConfirmationEmailShown = true;
                }
                const result = {
                  impersonate_subject: selectedChildCompany.subjectId,
                };
                const additionalRouteParts: any = {};
                additionalRouteParts["send_user_registration_email"] = "/";

                api.genericApiRequest({
                  entity: "subject",
                  method: "post",
                  entityId: user.subject_id,
                  additionalRouteParts: additionalRouteParts,
                  submitData: result,
                  successHandler: () => {
                    resolve();
                    setNotificationMessage(t("successfully_sent"));
                    setNotificationVariant("success");
                    setSuccessForNotification(true);
                    resetTable();
                    setInitialLoading(false);
                    setDefaultSortingLoaded(true);
                    bulkSendRegistrationConfirmationEmailShown = false;
                    setRowSelection({});
                  },
                  failHandler: (error: any) => {
                    reject(error);
                  },
                });
              });
            };

            if (props.readOnlyMode && props.readOnlyMode == true) {
              return (
                <div>
                  {props.heading && props.heading !== "" && (
                    <h1>{props.heading}</h1>
                  )}
                  {props.entity === "subjectExaminationDeadline" &&
                    props.subdomain === "manage" &&
                    props.customTopToolBarActionButton}
                  <div className={styles.flexContainer}></div>
                </div>
              );
            }

            return (
              <div>
                {props.heading && props.heading !== "" && (
                  <h1>{props.heading}</h1>
                )}
                {props.entity !== "permission" && (
                  <div className={styles.flexContainer}>
                    {!props.disableAddItem && (
                      <GenericButton onClick={() => handleOpenCreate()}>
                        {t("add")}
                      </GenericButton>
                    )}

                    {props.entity === "user" &&
                      Object.keys(table.getState().rowSelection).length > 0 && (
                        <>
                          <Tooltip
                            arrow
                            title={t("delete_selected_users")}
                            placement="bottom"
                          >
                            <GenericButton
                              variant="icon"
                              color="error"
                              disabled={
                                !table.getIsSomeRowsSelected() &&
                                !table.getIsAllPageRowsSelected()
                              }
                              onClick={() =>
                                handleBulkAction("deletePeople", selectedUsers)
                              }
                            >
                              <Delete />
                            </GenericButton>
                          </Tooltip>
                          {props.entity === "user" &&
                            selectedUsers.every((user) => user.is_deleted) && (
                              <>
                                <Tooltip
                                  arrow
                                  title={t("undelete_selected_users")}
                                  placement="bottom"
                                >
                                  <GenericButton
                                    color="iconBase"
                                    variant="icon"
                                    disabled={
                                      !table.getIsSomeRowsSelected() &&
                                      !table.getIsAllPageRowsSelected()
                                    }
                                    onClick={() =>
                                      handleBulkAction(
                                        "undeletePeople",
                                        selectedUsers
                                      )
                                    }
                                  >
                                    <RestoreFromTrashIcon />
                                  </GenericButton>
                                </Tooltip>
                              </>
                            )}
                          {selectedUsers.every((user) => !user.is_deleted) && (
                            <>
                              <Tooltip
                                arrow
                                title={t(
                                  "send_registration_email_to_selected_users"
                                )}
                                placement="bottom"
                              >
                                <GenericButton
                                  variant="icon"
                                  disabled={
                                    !table.getIsSomeRowsSelected() &&
                                    !table.getIsAllPageRowsSelected()
                                  }
                                  onClick={() =>
                                    handleBulkAction(
                                      "bulkSendRegistrationEmails",
                                      selectedUsers
                                    )
                                  }
                                >
                                  <EmailIcon />
                                </GenericButton>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={t("add_selected_users_to_department")}
                                placement="bottom"
                              >
                                <GenericButton
                                  variant="icon"
                                  onClick={() =>
                                    handleBulkAction(
                                      "bulkAddUsersToDepartments",
                                      selectedUsers
                                    )
                                  }
                                  disabled={
                                    !table.getIsSomeRowsSelected() &&
                                    !table.getIsAllPageRowsSelected()
                                  }
                                >
                                  <GroupsIcon />
                                </GenericButton>
                              </Tooltip>

                              {subdomain === "admin" && (
                                <>
                                  <Tooltip
                                    arrow
                                    title={t(
                                      "move_selected_users_to_another_company"
                                    )}
                                    placement="bottom"
                                  >
                                    <GenericButton
                                      variant="icon"
                                      onClick={() =>
                                        handleBulkAction(
                                          "bulkMoveUsersToCompany",
                                          selectedUsers
                                        )
                                      }
                                      disabled={
                                        !table.getIsSomeRowsSelected() &&
                                        !table.getIsAllPageRowsSelected()
                                      }
                                    >
                                      <PersonRemoveIcon />
                                    </GenericButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    {props.entity === "company" && (
                      <GenericButton
                        variant="outlined"
                        color="tertiary"
                        onClick={() => props.resetDemoCompany()}
                      >
                        {t("reset_demo")}
                      </GenericButton>
                    )}
                    {props.customTopToolBarActionButton &&
                      props.customTopToolBarActionButton}
                    {props.customTopToolBarAction && (
                      <div className={styles.topToolBarWrapper}>
                        {props.customTopToolBarAction}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          }}
          {...(props.customDetailPanel && {
            renderDetailPanel: ({ row }) => props.customDetailPanel(row),
          })}
        />
      )}
      <>
        <GenericCrudTableModal
          columns={props.columns}
          additionalComponentForModal={props.additionalComponentForModal}
          subjectIdForBuildings={props.subjectIdForBuildings}
          additionalColumnsForCreate={props.additionalColumnsForCreate}
          additionalColumnsForEdit={props.additionalColumnsForEdit}
          companySubjectId={props.companySubjectId}
          companyId={props.companyId}
          open={modalOpen}
          presetValues={modalPresetValues}
          modalMode={modalMode}
          customModalStyle={props.customModalStyle}
          userEmails={userEmails}
          entityOfTable={props.entity}
          onClose={() => {
            setModalPresetValues([]);
            setModalOpen(false);
          }}
          title={(function () {
            if (modalMode === "edit") {
              return t("edit_element");
            }
            return t("add_new_element");
          })()}
          submitButtonText={(function () {
            if (modalMode === "edit") {
              return t("save");
            }
            return t("add");
          })()}
          onSubmit={(function () {
            if (modalMode === "edit") {
              return handleSaveRow;
            }
            return handleCreateNewRow;
          })()}
        />

        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </>
      {showRefetchLoader && !isLoading && (
        <Backdrop className={styles.backdrop} open={true}>
          <div className={styles.loaderWrapper}>
            <DotLoader
              color="#8c1ec8"
              size={65}
              cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
            />
          </div>
        </Backdrop>
      )}
      {successForNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
}
