import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import { DotLoader } from "react-spinners";
import AppointmentBuildingInformation from "../appointment/appointmentBuildingInformation";
import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import DefaultInput from "../forms/inputs/defaultInput";
import PeopleIcon from "@mui/icons-material/People";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "./appointmentRequestBuildingInformation.module.css";
import GenericButton from "../forms/inputs/button/genericButton";

const AppointmentRequestBuildingInformation: FC = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [buildingInformation, setBuildingInformation] = useState<any>();
  const [buildingOptionsForSubject, setBuildingOptionsForSubject] =
    useState<any>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>();
  const [customTitel, setCustomTitle] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [invitedSubjects, setInvitedSubjects] = useState<any>([]);

  const [formValues, setFormValues] = useState<any>({
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    parking_situation: "",
    additional_information: "",
  });
  const [subjectId, setSubjectId] = useState<any>();
  const [error, setError] = useState<any>();
  const [currentAppointmentRequest, setCurrentAppointmentRequest] =
    useState<any>();

  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (appointmentrequestId) {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "get",
        entityId: appointmentrequestId,
        parametersToRender: { depth: "1" },
        successHandler: (res: any) => {
          setCurrentAppointmentRequest(res.data);
          setIsloading(false);
          setBuildingInformation(res.data.building_information);
          setSubjectId(res.data.company_subject_id);
          setSelectedBuilding(res.data.building_information.building);
          setCustomTitle(res.data.title);
          const tempInvited = [];
          for (const key in res.data.subject_info_set) {
            const subject = res.data.subject_info_set[key];
            tempInvited.push(subject);
            tempInvited.sort((a: any, b: any) => a.name.localeCompare(b.name));
          }
          setInvitedSubjects(tempInvited);
          setFormValues({
            contact_name:
              res.data.building_information.contact_name !== null
                ? res.data.building_information.contact_name
                : formValues.contact_name,
            contact_phone:
              res.data.building_information.contact_phone !== null
                ? res.data.building_information.contact_phone
                : formValues.contact_phone,
            contact_email:
              res.data.building_information.contact_email !== null
                ? res.data.building_information.contact_email
                : formValues.contact_email,
            parking_situation:
              res.data.building_information.parking_situation !== null
                ? res.data.building_information.parking_situation
                : formValues.parking_situation,
            additional_information:
              res.data.building_information.additional_information !== null
                ? res.data.building_information.additional_information
                : formValues.additional_information,
          });
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [appointmentrequestId]);

  useEffect(() => {
    if (subjectId !== undefined) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["subject"] = subjectId;
      api.genericApiRequest({
        entity: "building",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          const data = res.data.results;
          const tempBuildingOptions = [];
          for (const key in data) {
            const building = data[key];
            const value = building.id;
            const label =
              building.name +
              ` (${building?.street_house}, ${building?.zip} ${building?.city} )`;
            tempBuildingOptions.push({ value: value, label: label });
          }
          setBuildingOptionsForSubject(tempBuildingOptions);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [subjectId]);

  const handleTitleChange = (event: any) => {
    event.preventDefault();
    setCustomTitle(event.target.value);
  };

  const submitBuildingInformation = () => {
    const result = {
      building: selectedBuilding,
      contact_name: formValues.contact_name,
      contact_phone: formValues.contact_phone,
      contact_email: formValues.contact_email,
      parking_situation: formValues.parking_situation,
      additional_information: formValues.additional_information,
    };
    api.genericApiRequest({
      entity: "buildingInformation",
      method: "put",
      entityId: buildingInformation.id,
      successHandler: () => {
        if (currentAppointmentRequest.status !== "draft") {
          navigate(`/appointmentrequest`);
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
    });
  };

  const handleSubmit = () => {
    submitBuildingInformation();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = {
      title: customTitel,
      buildingInformation: buildingInformation.id,
    };

    if (currentAppointmentRequest.status === "draft") {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "put",
        entityId: appointmentrequestId,
        submitData: result,
        config: config,
        successHandler: () => {
          navigate(`/editappointmentrequest/${appointmentrequestId}/calendar`);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBuildingChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSelectedBuilding(selectedOption.value);
    }
  };

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Grid container className={styles.container} rowSpacing={"1rem"}>
          <Grid item xs={12}>
            {appointmentrequestId &&
              currentAppointmentRequest.status !== "draft" && (
                <div>
                  <Breadcrumbs separator="|">
                    <Link
                      className={styles.linkNoUnderline}
                      to={`/editappointmentrequest/${currentAppointmentRequest.id}/settings`}
                    >
                      {t("basic_information")}
                    </Link>
                    <Link
                      className={styles.linkUnderlined}
                      to={`/editappointmentrequest/${currentAppointmentRequest.id}/building`}
                    >
                      {t("building_location")}
                    </Link>
                    <Link
                      className={styles.linkNoUnderline}
                      to={`/editappointmentrequest/${currentAppointmentRequest.id}/calendar`}
                    >
                      {t("period")}
                    </Link>
                  </Breadcrumbs>
                </div>
              )}
            <h1 className={styles.heading}>
              {t("building_information")} -{" "}
              {currentAppointmentRequest?.company?.name}
              {/* {currentAppointmentRequest.status === "open" && ( */}
              <Tooltip title={t("show_invited")}>
                <GenericButton
                  data-testid="show-invited-btn"
                  onClick={() => setModalOpen(true)}
                  className={styles.showInvitedButton}
                >
                  <PeopleIcon />
                </GenericButton>
              </Tooltip>
              {/* )} */}
            </h1>
          </Grid>

          <Grid item xs={12}>
            <DefaultInput
              name="name"
              label={t("name")}
              value={customTitel}
              onChange={handleTitleChange}
              disabled={
                currentAppointmentRequest.status === "draft" ? true : false
              }
            />
          </Grid>
          <Grid container item xs={12} rowSpacing={"1rem"}>
            <AppointmentBuildingInformation
              buildingOptions={buildingOptionsForSubject}
              formValues={formValues}
              withGrid={true}
              handleInputChange={handleInputChange}
              handleBuildingChange={handleBuildingChange}
              selectedBuilding={selectedBuilding}
            />
          </Grid>
          <Grid item xs={12}>
            {appointmentrequestId &&
              currentAppointmentRequest.status !== "open" && (
                <GenericButton
                  data-testid="back-btn"
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/editappointmentrequest/${currentAppointmentRequest.id}/settings`
                    )
                  }
                >
                  {t("back")}
                </GenericButton>
              )}

            <GenericButton
              data-testid="submit-btn"
              className={styles.submitButton}
              onClick={handleSubmit}
            >
              {currentAppointmentRequest.status === "draft"
                ? t("continue_to_appointment_date_selection")
                : t("save")}
            </GenericButton>
          </Grid>
        </Grid>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      <Dialog
        open={modalOpen}
        scroll="paper"
        component={"div"}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle textAlign={"center"}>{t("invited_users")}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText display={"flex"} flexDirection={"column"}>
            <List>
              {invitedSubjects.map((subject: any) => (
                <ListItem key={subject.id} disablePadding>
                  <ListItemIcon>
                    {subject.appointment_created ? (
                      <CircleIcon color="success" fontSize="small" />
                    ) : (
                      <CircleIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={subject.name} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GenericButton
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {t("ok_capital")}
          </GenericButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentRequestBuildingInformation;
