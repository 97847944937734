import React, { FC, useState } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { replaceDate } from "../../helper/dateHandling";
import { useNavigate } from "react-router-dom";
import { Switch, Tooltip } from "@mui/material";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { KaerIcon } from "../../icons/kaerIcon";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

const ActionChainMainTable: FC = () => {
  const [refreshTrigger, setRefreshtrigger] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const navigate = useNavigate();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const handleNavigateChainEdit = (row: any) => {
    navigate(`/settings/actionChains/${row.original.id}`);
  };

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any
  ) => {
    const newTemplateEnabledState = event.target.checked;

    const confirmMessage = newTemplateEnabledState
      ? t("actionchain_setactive_confirm", { title: row.name })
      : t("actionchain_setinactive_confirm", { title: row.name });
    const result = { enabled: newTemplateEnabledState };
    const confirmDelete = await showConfirmationModalAsync(confirmMessage);

    if (!confirmDelete) return;

    api.genericApiRequest({
      method: "put",
      entity: "actionChainTemplate",
      entityId: row.id,
      submitData: result,
      successHandler: () => {
        setRefreshtrigger((prev) => !prev);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const actionChainTemplateColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("name"),
      accessorKey: "name",
      id: "name",
      enableForCreate: true,
    },
    {
      header: t("active"),
      accessorKey: "enabled",
      id: "enabled",
      accessorFn: (row: any) => {
        if (row.enabled !== null) {
          if (row.enabled) {
            return t("yes");
          } else {
            return t("no");
          }
        } else return "-";
      },
    },
    {
      header: t("created_by"),
      accessorKey: "created_by",
      id: "created_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      sortingFn: "datetime",
      filterVariant: "date",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_by"),
      accessorKey: "modified_by",
      id: "modified_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      id: "modified_on",
      filterVariant: "date",

      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
  ];

  const customRowActions = (row: any) => {
    return (
      <>
        <Tooltip arrow placement="right" title={t("actionchain_edit")}>
          <GenericButton
            variant="icon"
            color="iconBase"
            onClick={() => {
              handleNavigateChainEdit(row);
            }}
          >
            <KaerIcon icon="actionChain" />
          </GenericButton>
        </Tooltip>
        <Tooltip
          arrow
          placement="right"
          title={
            row.original.enabled
              ? t("actionchain_setinactive")
              : t("actionchain_setactive")
          }
        >
          <Switch
            checked={row.original.enabled}
            onChange={(event: any) => handleChange(event, row.original)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <GenericCrudTable
        heading={t("actionchain_templates")}
        columns={actionChainTemplateColumns}
        entity="actionChainTemplate"
        customRowActions={customRowActions}
        refreshTable={refreshTrigger}
      />
      {error && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default ActionChainMainTable;
