import React, { useState } from "react";
import GenericConfirmationModal from "../components/forms/comfirmations/genericConfirmationModal";
import { ConfirmationModalContext } from "./confirmationModalContext";
import { t } from "i18next";

type ConfirmationModalProps = {
  isOpen: boolean;
  title?: string;
  message: string;
  confirmButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  //   resolve?: (value: boolean) => void;
};

export const ConfirmationModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modalState, setModalState] = useState<ConfirmationModalProps>({
    isOpen: false,
    message: "",
  });

  const showConfirmationModal = (
    message: string,
    options?: {
      title?: string;
      confirmButtonText?: string;
      onConfirm?: () => void;
      onCancel?: () => void;
    }
  ) => {
    setModalState({
      isOpen: true,
      message,
      title: options?.title || t("confirm_modal_close_title"),
      confirmButtonText: options?.confirmButtonText || t("ok_capital"),
      onConfirm: () => {
        options?.onConfirm?.();
        setModalState({ isOpen: false, message: "" });
      },
      onCancel: () => {
        options?.onCancel?.();
        setModalState({ isOpen: false, message: "" });
      },
    });
  };
  const showConfirmationModalAsync = (
    message: string,
    options?: { title?: string; confirmButtonText?: string }
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalState({
        isOpen: true,
        message,
        title: options?.title || t("confirm_modal_close_title"),
        confirmButtonText: options?.confirmButtonText || t("ok_capital"),
        onConfirm: () => {
          resolve(true);
          setModalState({ isOpen: false, message: "" });
        },
        onCancel: () => {
          resolve(false);
          setModalState({ isOpen: false, message: "" });
        },
      });
    });
  };

  return (
    <ConfirmationModalContext.Provider
      value={{ showConfirmationModal, showConfirmationModalAsync }}
    >
      {children}
      <GenericConfirmationModal {...modalState} />
    </ConfirmationModalContext.Provider>
  );
};
