import { useState, useEffect, FC } from "react";
import { formValues } from "../appointmentDashboard/appointmentTreeView";
import { useTranslation } from "react-i18next";
import DefaultForm from "../forms/defaultForm";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  coreDataId?: string;
  formId?: string;
  formValues?: formValues;
  onSuccessfulFormSubmitHandler: () => void;
  onCancelFormHandler: () => void;
  readOnly?: boolean;
  probeId?: any;
  subjectId?: any;
  reload?: () => void;
}
const CoreDataForm: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [formValues, setFormValues]: any = useState<any>({});
  const [formfieldsDefinition, setFormfieldsDefinition] = useState<any>([]);
  const [formfieldsVisibility, setFormfieldsVisibility]: any = useState({});
  const [formfieldsVisibilityRulesets, setFormfieldsVisibilityRulesets]: any =
    useState({});
  const [measurementDefinitionToFormSet, setMeasurementDefinitionToFormSet] =
    useState<any>([]);
  const [error, setError] = useState<any>();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const coreDataId = props.coreDataId;
  const formId = props.formId;

  useEffect(() => {
    if (Array.isArray(props.formValues) && props.formValues.length > 0) {
      const formValues: any = {};
      for (const key in props.formValues) {
        const formValue = props.formValues[key];
        formValues[formValue.name] = formValue.value;
      }
      setFormValues(formValues);
    }
    if (coreDataId !== "") {
      api.genericApiRequest({
        entity: "form",
        method: "get",
        successHandler: (res: any) => {
          const forms = res.data;
          setMeasurementDefinitionToFormSet(
            forms.measurementdefinitiontoform_set
          );
          const currentFormId = forms.id;
          if (String(currentFormId) === String(formId)) {
            setFormfieldsDefinition(forms.measurementdefinitiontoform_set);
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: formId,
      });
    }
    props.reload?.();
  }, []);

  useEffect(() => {
    recalculateFormfieldVisiblity();
  }, [formValues]);

  useEffect(() => {
    prepareVisibilityRulesets(formfieldsDefinition);
    recalculateFormfieldVisiblity();
  }, [formfieldsDefinition]);

  const prepareVisibilityRulesets = function (fields: any) {
    for (const field in fields) {
      const item = fields[field];
      const fieldname = item.measurement_definition.name;

      if (item.visibility_ruleset) {
        let visibility_ruleset = item.visibility_ruleset;
        if (
          typeof item.visibility_ruleset === "string" ||
          item.visibility_ruleset instanceof String
        ) {
          visibility_ruleset = JSON.parse(item.visibility_ruleset);
        }

        if (
          typeof visibility_ruleset === "object" &&
          Object.entries(visibility_ruleset).length > 0
        ) {
          formfieldsVisibilityRulesets[fieldname] = visibility_ruleset;
        }
      }
    }

    setFormfieldsVisibilityRulesets(formfieldsVisibilityRulesets);
  };

  const recalculateFormfieldVisiblity = function () {
    if (
      formfieldsVisibilityRulesets &&
      Object.keys(formfieldsVisibilityRulesets).length > 0
    ) {
      const newFormfieldsVisibility: any = {};
      for (const key of Object.keys(formfieldsVisibilityRulesets)) {
        const currentFieldRules = formfieldsVisibilityRulesets[key];
        newFormfieldsVisibility[key] =
          parseAndApplyVisibilityRules(currentFieldRules);
      }

      setFormfieldsVisibility(newFormfieldsVisibility);
    }
  };

  const parseAndApplyVisibilityRules = function (visibility_ruleset: any) {
    const visibleResults = [];

    for (const visibility_ruleset_item of Object.entries(visibility_ruleset)) {
      const visibility_ruleset_item_name = visibility_ruleset_item[0];
      const visibility_ruleset_item_value: any = visibility_ruleset_item[1];
      if (
        typeof visibility_ruleset_item_value === "object" &&
        Object.entries(visibility_ruleset_item_value).length > 0
      ) {
        for (const single_rule of Object.entries(
          visibility_ruleset_item_value
        )) {
          visibleResults.push(
            validateVisibilityRule(visibility_ruleset_item_name, single_rule)
          );
        }
      }
    }

    if (visibleResults.includes(false)) {
      return false;
    }

    return true;
  };

  const validateVisibilityRule = function (valueName: string, rule: any) {
    const rule_type = rule[0];
    const rule_value = rule[1];
    switch (rule_type) {
      case "is":
        if (formValues[valueName] == rule_value) {
          return true;
        }
        break;
      case "not":
        if (formValues[valueName] != rule_value) {
          return true;
        }
        break;
      case "lt":
        if (formValues[valueName] < rule_value) {
          return true;
        }
        break;
      case "gt":
        if (formValues[valueName] > rule_value) {
          return true;
        }
        break;
      case "in":
        if (Array.isArray(rule_value)) {
          if (rule_value.includes(formValues[valueName])) {
            return true;
          }
        }
        break;
      case "nin":
        if (
          Array.isArray(rule_value) &&
          !rule_value.includes(formValues[valueName])
        ) {
          return true;
        }
        break;
    }

    return false;
  };

  const handleFormItemChange = function (event: any) {
    if (event.target.id) {
      setFormValues({ ...formValues, [event.target.id]: event.target.value });
    }
  };
  // select doesnt have an event/id/target - save as label? / name?
  const handleDropDownChange = function (selectedOption: any, label: any) {
    let selectedValues: any = [];
    if (!Array.isArray(selectedOption)) {
      selectedValues = selectedOption?.value;
    } else {
      for (const key in selectedOption) {
        const value = selectedOption[key].value;
        selectedValues.push(value);
      }
    }

    if (label) {
      handleFormItemChange({
        target: { id: label, value: selectedValues },
      });
    }
  };

  const preSubmitHandler = (data: any) => {
    const metrics: any = [];
    for (const key in data) {
      const field = key;
      const value = data[key];
      const findMeasurement = measurementDefinitionToFormSet.find(
        (item: any) => item.measurement_definition.name === field
      );
      if (value !== undefined) {
        metrics.push({
          unit: "",
          name: field,
          measurement_definition: String(
            findMeasurement.measurement_definition.id
          ),
          value: value,
        });
      }
    }

    const result = {
      coredata_id: props.coreDataId,
      metrics: metrics,
    };

    return result;
  };

  const renderFormFields = function (fields: any) {
    const inputElements: any = [];
    for (const field in fields) {
      const item = fields[field];
      const fieldname = item.measurement_definition.name;
      const label = item.measurement_definition.label;
      const type = item.measurement_definition.type;

      let options: any = [];
      if (type === "choice" || type === "multiselect") {
        if (
          Array.isArray(item.measurement_definition.value_map) &&
          item.measurement_definition.value_map.length !== 0
        ) {
          options = item.measurement_definition.value_map.map((obj: any) => {
            const [label, value] = Object.entries(obj)[0];
            return { value: value, label: label };
          });
        }
      }

      inputElements.push({
        // preDefinedValues: formValues[fieldname],
        type: type,
        name: fieldname,
        label: label,
        options: options,
        visibility: formfieldsVisibility,
        required: item.mandatory,
        errorMessageRequired: "Bitte machen Sie eine Eingabe",
        errorMessageValidation:
          "Unzulässige Zeichen. Bitten passen Sie Ihre Eingabe an.",
        // fieldname: fieldname,
        onChange: handleFormItemChange,
        onDropdownChange: handleDropDownChange,
        order: item.order,
      });
    }

    return (
      <DefaultForm
        withPaper={true}
        entity={"coreData"}
        entityId={formId}
        targetEntity={"metric/submit_metrics"}
        preDefinedData={formValues}
        input={inputElements}
        visibility={formfieldsVisibility}
        findingId={coreDataId}
        onSuccessHandler={props.onSuccessfulFormSubmitHandler}
        onCancelFormHandler={async () => {
          const isConfirmed = await showConfirmationModalAsync(
            t("confirm_cancel_form_input")
          );
          if (!isConfirmed) return;
          props.onCancelFormHandler();
        }}
        cancelButton={true}
        preSubmitHandler={preSubmitHandler}
      />
    );
  };

  let formFields: any = <></>;

  if (formfieldsDefinition != "") {
    formFields = renderFormFields(formfieldsDefinition);
  }

  return (
    <>
      {formFields}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default CoreDataForm;
