import { FC } from "react";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { t } from "i18next";
import { Link } from "react-router-dom";
import styles from "./appointmentTreeItemSubjectInformationList.module.css";
import dayjs from "dayjs";

interface Props {
  appointment: any;
  handleNavigate?: any;
  handleNavigateToCompany?: any;
}

const AppointmentTreeItemSubjectInformationList: FC<Props> = ({
  appointment,
  handleNavigate,
  handleNavigateToCompany,
}) => {
  const getAppointmentAddress = () => {
    const buildingInformation = appointment.building_information;

    if (
      buildingInformation.street_house &&
      buildingInformation.city &&
      buildingInformation.zip
    ) {
      return (
        buildingInformation.street_house +
        ", " +
        buildingInformation.zip +
        " " +
        buildingInformation.city
      );
    } else {
      return "-";
    }
  };

  const getAppointmentContactName = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.contact_name) {
      return buildingInformation?.contact_name;
    } else {
      return "-";
    }
  };
  const getAppointmentContactPhone = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.contact_phone) {
      return buildingInformation?.contact_phone;
    } else {
      return "-";
    }
  };
  const getAppointmentContactEmail = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.contact_email) {
      return (
        <a
          className={styles.emailLink}
          href={`mailto:${buildingInformation.contact_email}`}
        >
          {buildingInformation.contact_email}
        </a>
      );
    } else {
      return "-";
    }
  };
  const getAppointmentBuilding = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.name) {
      return buildingInformation.name;
    } else {
      return "-";
    }
  };
  const getAppointmentAdditionalInformation = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.additional_information) {
      return buildingInformation.additional_information;
    } else {
      return "-";
    }
  };
  const getAppointmentParkingSituation = () => {
    const buildingInformation = appointment.building_information;

    if (buildingInformation.parking_situation) {
      return buildingInformation.parking_situation;
    } else {
      return "-";
    }
  };

  const customListItem = (props: any) => {
    return (
      <ListItem {...props} sx={{ padding: "0" }}>
        {props.children}
      </ListItem>
    );
  };

  const renderSubjectName = () => {
    const today = dayjs();
    const name = appointment.subject?.name;
    const sex = appointment.subject?.person?.sex;
    const birthDate = dayjs(appointment.subject?.person?.birth_date).format(
      "DD.MM.YYYY"
    );
    const birthForCalculation = dayjs(
      new Date(appointment.subject?.person?.birth_date)
    );

    const age = today.diff(birthForCalculation, "year") + " " + t("year_age");

    let result = name;
    if (!appointment.subject?.is_company) {
      if (sex) {
        let tempSex;
        switch (sex) {
          case "male":
            tempSex = t("male").toLowerCase();
            break;
          case "female":
            tempSex = t("female").toLowerCase();
            break;
          case "diverse":
            tempSex = t("diverse").toLowerCase();
            break;
        }
        result += `, ${tempSex}`;
      }
      if (birthDate) {
        result += `, ${birthDate} (${age})`;
      }
    }

    return <>{result}</>;
  };

  const renderCompanyItem = () => {
    let result = appointment.subject.company.name;

    if (!appointment.subject?.is_company) {
      result = (
        <Link
          className={styles.link}
          to="/subjectOverview"
          target="_blank"
          onClick={handleNavigateToCompany}
        >
          {appointment.subject?.company?.name}
        </Link>
      );
    }
    return <>{result}</>;
  };

  return (
    <Grid item container xs={12} component={List} className={styles.container}>
      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("building_subject")}
          secondary={
            <Link
              className={styles.link}
              to="/subjectOverview"
              onClick={handleNavigate}
              target="_blank"
            >
              {renderSubjectName()}
            </Link>
          }
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>
      {!appointment.subject?.is_company && (
        <Grid xs={4} item component={customListItem}>
          <ListItemText
            primary={t("email")}
            secondary={
              <a
                className={styles.link}
                href={`mailto:${appointment.subject?.email}`}
              >
                {appointment.subject?.email}
              </a>
            }
            primaryTypographyProps={{ className: styles.primaryText }}
            secondaryTypographyProps={{ className: styles.secondaryText }}
          />
        </Grid>
      )}
      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("company")}
          secondary={renderCompanyItem()}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          primary={t("building_location")}
          secondary={getAppointmentAddress()}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentBuilding()}
          primary={t("buildings")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentContactName()}
          primary={t("building_contact_name")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>
      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentContactPhone()}
          primary={t("building_contact_phone")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      <Grid xs={4} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentContactEmail()}
          primary={t("building_contact_email")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryText }}
        />
      </Grid>

      {/* additional_information and parking_situation full width because the information can be multiple lines  */}
      <Grid xs={12} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentAdditionalInformation()}
          primary={t("building_additional_information")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryTextNoBold }}
        />
      </Grid>

      <Grid xs={12} item component={customListItem}>
        <ListItemText
          secondary={getAppointmentParkingSituation()}
          primary={t("building_parking_situation")}
          primaryTypographyProps={{ className: styles.primaryText }}
          secondaryTypographyProps={{ className: styles.secondaryTextNoBold }}
        />
      </Grid>
    </Grid>
  );
};

export default AppointmentTreeItemSubjectInformationList;
