import { FC } from "react";
import DefaultInput from "../forms/inputs/defaultInput";
import styles from "./measurementDefinitionSelect.module.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { t } from "i18next";
import GenericButton from "../forms/inputs/button/genericButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  id?: string | undefined;
  onChange: (id: string | undefined, value: string, label: string) => void;

  onDelete?: (id: string | undefined) => void;
  index: any;
  selectedInputCount: any;
  moveUp: any;
  moveDown?: any;
  label: any;
  value: any;
}

const MeasurementDefinitionSelect: FC<Props> = (props: Props) => {
  const handleValueChange = (event: any) => {
    props.onChange(props.id, event.target.value, props.label);
  };

  const handleLabelChange = (event: any) => {
    props.onChange(props.id, props.value, event.target.value);
  };

  return (
    <div className={styles.inputWrapper} id={props.id}>
      <DefaultInput
        label={t("label_alternative_1")}
        name={props.id}
        onChange={handleLabelChange}
        value={props.label}
      />
      <DefaultInput
        label={t("value")}
        name={props.id}
        onChange={handleValueChange}
        value={props.value}
      />
      <section className={styles.selectOptions_actionbuttonwrapper}>
        <GenericButton
          variant="icon"
          onClick={() => props.moveUp(props.index)}
          disabled={props.index === 0}
        >
          <ArrowDropUpIcon />
        </GenericButton>
        <GenericButton
          variant="icon"
          onClick={() => props.moveDown(props.index)}
          disabled={props.index === props.selectedInputCount.length - 1}
        >
          <ArrowDropDownIcon />
        </GenericButton>
        <GenericButton
          startIcon={<DeleteIcon />}
          onClick={() => props?.onDelete?.(props.id)}
        >
          {t("delete")}
        </GenericButton>
      </section>
    </div>
  );
};

export default MeasurementDefinitionSelect;
