import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultInput from "./inputs/defaultInput";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import Background from "../../icons/background";
import "../styles/login.css";
import { api } from "../../helper/api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import { useForm } from "react-hook-form";
import PasswordInput from "./inputs/passwordInput";
import styles from "./forgottenPasswordForm.module.css";
import GenericButton from "./inputs/button/genericButton";
import { emailValidationRegex } from "../../helper/regex";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  title?: string;
}

const ForgottenPasswordForm: FC<Props> = () => {
  const [userName, setUserName] = useState<string>("");
  const [expiredLinkError, setExpiredLinkError] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { t } = useTranslation();
  const theme = useTheme();
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const { userId, passwordResetToken } = useParams() as {
    userId: string;
    passwordResetToken: string;
  };

  const { handleSubmit, control, watch, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
  });
  const { showConfirmationModal } = useConfirmationModal();

  const navigate = useNavigate();
  const passwordRegex = new RegExp(
    /^(?=.*[0-9])(?=.*[!=?\-_+/&$#*.,:;@]).{10,}$/
  );
  const newPassword = watch("password");

  const svgString = encodeURIComponent(renderToStaticMarkup(<Background />));

  const handleUsernameChange = (event: any) => {
    setUserName(event.target.value);
  };

  const handleMailSubmit = (event: any) => {
    event.preventDefault();
    const validEmail = emailValidationRegex.test(userName);

    const result = { email: userName };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (validEmail) {
      api.genericApiRequest({
        entity: "user/reset_forgotten_password",
        method: "put",
        successHandler: () => {
          setNotificationMessage(t("emailPasswordreset_send"));
          setNotificationVariant("success");
          setShowNotification(true);
          navigate("/login/");
        },
        failHandler: (error: any, status: any) => {
          if (status === 500) {
            setError(t("error_default_contact_service"));
          } else setError(error);
        },
        submitData: result,
        config: config,
      });
    } else {
      setError(t("enter_valid_email"));
    }
  };
  const onPasswordSubmit = (data: any) => {
    const result = {
      password: data.password,
      token: passwordResetToken,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["reset_password"] = "/";
    api.genericApiRequest({
      entity: "user",
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("passwordSet_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        navigate("/login/");
      },
      failHandler: (error: any, status: any) => {
        setError(error);
        if (status === 401) {
          setExpiredLinkError(true);
        }
      },
      entityId: userId,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
      config: config,
    });
  };

  const formWrapperClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? `${styles.formWrapper} ${styles.wideForm}`
    : styles.formWrapper;

  const buttonStyleClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? styles.autoWidthButton
    : styles.fullWidthButton;

  const centerStyleClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? `${styles.centerStyle} ${styles.noWrap}`
    : styles.centerStyle;

  const expiredLinkErrorMessage = (
    <div className={styles.errorMessage}>
      <ErrorOutlineIcon />
      <div className={styles.messageWrapper}>
        <span className={styles.errorSpan}>
          {t("password_reset_expired_link_error_message")}
        </span>
        <span className={styles.errorSpan}>
          {t("password_reset_expired_link_contact_before_email_address")} {""}
          <a className={styles.emailLink} href="mailto:service@kaerhealth.com">
            {" "}
            service@kaerhealth.com
          </a>
          , {t("password_reset_expired_link_contact_after_email_address")}
        </span>
      </div>
    </div>
  );

  const additionalPasswordCheckWithAlert = (
    pwd: any,
    confirmedPwd: any,
    regEx: RegExp
  ) => {
    if (pwd !== confirmedPwd) {
      showConfirmationModal(t("password_dont_match"));
    } else if (!regEx.test(pwd)) {
      showConfirmationModal(t("password_requirements"));
    }
  };
  const renderContent = () => {
    if (!passwordResetToken) {
      return (
        <div className={formWrapperClass}>
          <section className="flexColumn alignItems">
            <h1 className="heading heading_Mb textAlignCenter">
              {t("reset_password")}
            </h1>
            <p className="passwordRestMessage textAlignCenter">
              {t("reset_password_description")}
            </p>
          </section>
          <form onSubmit={handleMailSubmit} className="emailForm">
            <DefaultInput
              label={t("login_email")}
              value={userName}
              onChange={handleUsernameChange}
              labelClassName={styles.defaultInputLabel}
              inputClassName="passwordResetInput"
            />

            <div className={centerStyleClass}>
              <GenericButton
                color="secondary"
                className={`passwordReset_cancelButton ${buttonStyleClass}`}
                onClick={() => navigate("/login/")}
              >
                {t("back")}
              </GenericButton>

              <GenericButton
                type="submit"
                className={`passwordReset_emailSubmitButton ${buttonStyleClass}`}
              >
                {t("send")}
              </GenericButton>
            </div>
          </form>
        </div>
      );
    } else if (passwordResetToken) {
      return (
        <>
          <div className={formWrapperClass}>
            <form
              onSubmit={handleSubmit(onPasswordSubmit)}
              className="registerForm"
              noValidate
            >
              <h1 className="heading textAlignCenter">{t("reset_password")}</h1>
              <PasswordInput
                label={
                  <div>
                    <span className={styles.passwordLabel}>
                      {t("new_password")}
                    </span>
                    <span>{t("password_requirements")}</span>
                  </div>
                }
                id={t("new_password")}
                title={t("new_password")}
                dynamicLabel={false}
                control={control}
                name={"password"}
                required={true}
                requiredErrorMessage={t("field_required")}
                pattern={passwordRegex}
                patternErrorMessage={t("password_requirements")}
                errorFontColor={"#FFF"}
              />
              <PasswordInput
                id={t("confirm_password")}
                title={t("confirm_password")}
                label={t("confirm_password")}
                dynamicLabel={false}
                control={control}
                name={"confirmedPassword"}
                required={true}
                requiredErrorMessage={t("field_required")}
                errorFontColor={"#FFF"}
                validationRule={newPassword}
                validationRuleErrorMessage={t("password_dont_match")}
              />
              <div className={centerStyleClass}>
                <GenericButton
                  type="submit"
                  className="passwordReset_submitButton"
                  onClick={() => {
                    additionalPasswordCheckWithAlert(
                      getValues("password"),
                      getValues("confirmedPassword"),
                      passwordRegex
                    );
                  }}
                >
                  {t("send")}
                </GenericButton>
              </div>
            </form>
          </div>

          <Modal
            open={expiredLinkError}
            onClose={() => setExpiredLinkError(false)}
            className={styles.expiredLinkModal}
          >
            {expiredLinkErrorMessage}
          </Modal>
        </>
      );
    }
  };

  return (
    <div
      className={styles.loginWrapper}
      style={{ background: `url('data:image/svg+xml;utf8, ${svgString}')` }}
    >
      {renderContent()}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </div>
  );
};

export default ForgottenPasswordForm;
