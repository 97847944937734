export const mimeTypeToExtension = (mimeType: string) => {
  const map: Record<string, string> = {
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/pdf": "pdf",
    "text/csv": "csv",
    "image/jpeg": "jpeg",
    "image/png": "png",
    "image/jpg": "jpg",
    "application/octet-stream": "bin",
  };

  return map[mimeType] || "bin";
};
