import { FormControlLabel, Paper, Tooltip } from "@mui/material";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { DotLoader } from "react-spinners";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import styles from "./trackedService.module.css";
import { RadioButtonsWithRef } from "../forms/inputsWithRef/radioButtonsWithRef";
import GenericControllerWithInput from "../forms/inputsWithController/GenericController";
import { TextAreaWithRef } from "../forms/inputsWithRef/textAreaWithRef";
import { InputWithRef } from "../forms/inputsWithRef/inputWithRef";
import DropDown from "../forms/inputs/dropDown";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { eurocentToEuro, euroToEurocent } from "../../helper/formattingValues";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import GenericButton from "../forms/inputs/button/genericButton";

type FormValues = {
  company_subject: { value: string; label: string } | string;
  subject: { value: string; label: string } | string;
  on_site: boolean;
  description: string;
  estimated_duration: number;
  actual_duration: number;
  price: number;
  category: string;
  tracked_service_type: string;
  tracked_service_template: string;
  info_finance: string;
  info_ops: string;
  billed_by: string;
  checked_by: string;
  executed_by: string | number;
  executed_on: string | Date | Dayjs;
  building: { value: string; label: string } | string;
  // metadata: any;
  // appointment: any;
  // billed_on: Date;
  // checked_on: Date;
};
type Mode = "create" | "edit";

const TrackedServiceCustomCrud: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [formMode, setFormMode] = useState<Mode>("create");
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [trackedServiceTypeOptions, setTrackedServiceTypeOptions] = useState<
    any[]
  >([]);
  const [trackedServiceTemplateOptions, setTrackedServiceTemplateOptions] =
    useState<any[]>([]);
  const [kaerEmployeesOptions, setKaerEmployeesOptions] = useState<any[]>([]);
  const [buildingOptions, setBuildingOptions] = useState<any[]>([]);

  const { trackedServiceId } = useParams() as { trackedServiceId: string };
  const navigate = useNavigate();
  const loginStateForExecutedBy = useSelector(
    (state: RootState) => state.login
  );

  const { control, handleSubmit, watch, setValue } = useForm<FormValues>({
    defaultValues: {},
  });
  const companySubject = watch("company_subject");
  const trackedServiceTemplate = watch("tracked_service_template");
  const shouldShowFields =
    formMode === "edit" || (companySubject && trackedServiceTemplate);
  useEffect(() => {
    getMainCategories(setCategoryOptions, setError);
    getKaerEmployees(setKaerEmployeesOptions, setError);
    getTrackedServiceTypes(setTrackedServiceTypeOptions, setError);
    getTrackedServiceTemplates(setTrackedServiceTemplateOptions, setError);
  }, []);

  useEffect(() => {
    if (
      companySubject !== undefined &&
      typeof companySubject !== "string" &&
      companySubject.value !== undefined
    ) {
      getBuildings(setBuildingOptions, companySubject.value, setError);
    }
  }, [companySubject]);

  useEffect(() => {
    if (trackedServiceId !== undefined) {
      api.genericApiRequest({
        method: "get",
        entity: "trackedService",
        entityId: trackedServiceId,
        successHandler: (res: any) => {
          const fieldsToUpdateOnEdit: Array<keyof FormValues> = [
            "company_subject",
            "tracked_service_template",
            "subject",
            "on_site",
            "description",
            "estimated_duration",
            "actual_duration",
            "price",
            "category",
            "tracked_service_type",
            "info_ops",
            "info_finance",
            "executed_by",
            "executed_on",
            "building",
          ];

          Object.entries(res.data).forEach(([key, value]) => {
            if (fieldsToUpdateOnEdit.includes(key as keyof FormValues)) {
              let finalValue:
                | string
                | number
                | boolean
                | { value: any; label: string }
                | Date
                | dayjs.Dayjs
                | undefined;

              if (key === "executed_on" && typeof value === "string") {
                finalValue = dayjs(value);
              } else if (key === "company_subject" || key === "subject") {
                if (value === null || value === undefined) {
                  finalValue = undefined;
                } else if (
                  typeof value === "object" &&
                  "id" in value &&
                  "name" in value
                ) {
                  finalValue = {
                    value: (value as { id: any }).id,
                    label: (value as { name: string }).name,
                  };
                }
              } else if (
                ["tracked_service_template"].includes(key) &&
                value !== null &&
                typeof value === "object" &&
                "id" in value
              ) {
                finalValue = (value as { id: any }).id;
              } else {
                finalValue =
                  typeof value === "object" && value !== null && "id" in value
                    ? (value as { id: any }).id
                    : (value as string | number | boolean);
              }

              if (finalValue !== undefined) {
                setValue(key as keyof FormValues, finalValue);
              }
            }
          });
          setFormMode("edit");
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [trackedServiceId]);
  useEffect(() => {
    if (trackedServiceId === undefined) {
      setFormMode("create");
      setIsLoading(false);
    }
  }, [trackedServiceId]);

  useEffect(() => {
    if (trackedServiceTemplate && formMode === "create") {
      getTrackedServiceTemplateValues(
        trackedServiceTemplate,
        setValue,
        setError
      );
    }
  }, [trackedServiceTemplate, formMode]);

  useEffect(() => {
    const executedBy = watch("executed_by");
    const executedOn = watch("executed_on");
    if (formMode === "create") {
      if (executedBy == null) {
        setValue("executed_by", loginStateForExecutedBy.subjectId);
      }
      if (executedOn == null) {
        setValue("executed_on", dayjs().format("YYYY-MM-DD"));
      }
    }
  }, [formMode]);

  const submitTrackedService = (data: any) => {
    const addIfDefined = (targetObject: any, keyToAssign: any, value: any) => {
      if (value !== undefined) targetObject[keyToAssign] = value;
    };
    const result = { ...data };
    addIfDefined(result, "company_subject", data.company_subject?.value);
    addIfDefined(result, "subject", data.subject?.value);
    addIfDefined(result, "on_site", data.on_site === "yes" ? true : false);
    addIfDefined(result, "executed_by", data.executed_by?.value);
    addIfDefined(
      result,
      "tracked_service_template",
      data.tracked_service_template?.value
    );
    addIfDefined(
      result,
      "tracked_service_type",
      data.tracked_service_type?.value
    );
    addIfDefined(result, "category", data.category?.value);
    addIfDefined(result, "building", data.building?.value);

    if (formMode === "create") {
      postTrackedService("post", result, setError, handleNavigateBack);
    } else {
      postTrackedService(
        "put",
        result,
        setError,
        handleNavigateBack,
        trackedServiceId
      );
    }
  };
  const handleNavigateBack = () => {
    navigate("/trackedservice/recorded/");
  };

  return (
    <section
      aria-labelledby="form-wrapper"
      style={{
        width: "100%",
      }}
    >
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <>
          <Tooltip
            placement="right"
            title={t("back_to", { name: t("company") })}
          >
            <GenericButton
              variant="icon"
              color="iconBase"
              onClick={handleNavigateBack}
            >
              <ArrowBackIcon />
            </GenericButton>
          </Tooltip>
          <h1 className={styles.heading}>
            {formMode === "create"
              ? t("trackedservice_create")
              : t("trackedservice_edit")}
          </h1>
          <Paper className={styles.formPaperWrapper}>
            <form onSubmit={handleSubmit(submitTrackedService)} noValidate>
              <GenericControllerWithInput
                control={control}
                name="company_subject"
                rules={{
                  required: {
                    value: true,
                    message: t("field_required"),
                  },
                }}
                renderInput={({ onChange, ref, value }) => (
                  <DropDownSubjectSearch
                    customRef={ref}
                    onChange={onChange}
                    subdomain={"admin"}
                    queryParameter={"companies"}
                    customLabel={t("company")}
                    isClearable={true}
                    useGlobalState={false}
                    preExistingValue={value || null}
                    mandatory={true}
                  />
                )}
              />
              <GenericControllerWithInput
                control={control}
                name="tracked_service_template"
                rules={{
                  required: {
                    value: true,
                    message: t("field_required"),
                  },
                }}
                renderInput={({ onChange, value }) => (
                  <DropDown
                    options={trackedServiceTemplateOptions}
                    onChange={onChange}
                    name="tracked_service_template"
                    label={t("trackedservice_template")}
                    defaultValue={value}
                    mandatory={true}
                  />
                )}
              />
              {shouldShowFields && (
                <>
                  {formMode === "edit" && (
                    <>
                      <GenericControllerWithInput
                        control={control}
                        name="subject"
                        renderInput={({ onChange, ref, value }) => (
                          <DropDownSubjectSearch
                            customRef={ref}
                            onChange={onChange}
                            subdomain={"admin"}
                            customLabel={t("subject")}
                            isClearable={true}
                            useGlobalState={false}
                            preExistingValue={value || null}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="on_site"
                        renderInput={({ onChange, ref, value }) => (
                          <RadioButtonsWithRef
                            name={"on_site"}
                            label={t("trackedservice_on_site")}
                            onChange={onChange}
                            ref={ref}
                            defaultValue={value}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="building"
                        renderInput={({ onChange, value }) => (
                          <DropDown
                            options={buildingOptions}
                            onChange={onChange}
                            name="building"
                            label={t("building_location")}
                            defaultValue={value}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="description"
                        renderInput={({ onChange, ref, value }) => (
                          <TextAreaWithRef
                            label={t("description")}
                            name="description"
                            onChange={onChange}
                            ref={ref}
                            value={value}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="estimated_duration"
                        rules={{
                          required: {
                            value: true,
                            message: t("field_required"),
                          },
                        }}
                        renderInput={({ onChange, ref, value }) => (
                          <InputWithRef
                            name="estimated_duration"
                            onChange={onChange}
                            label={t("trackedservice_estimated_duration")}
                            type="number"
                            ref={ref}
                            value={value}
                            mandatory={true}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="price"
                        convertCurrency={true}
                        rules={{
                          required: {
                            value: true,
                            message: t("field_required"),
                          },
                        }}
                        renderInput={({ onChange, ref, value }) => {
                          const displayValue = eurocentToEuro(value);

                          const handlePriceChange = (e: any) => {
                            const euroValue = parseFloat(e.target.value);
                            if (!isNaN(euroValue)) {
                              onChange(euroToEurocent(euroValue));
                            }
                          };

                          const handleBlur = (e: any) => {
                            const euroValue = parseFloat(e.target.value);
                            if (!isNaN(euroValue)) {
                              e.target.value = euroValue.toFixed(2);
                            }
                          };
                          return (
                            <InputWithRef
                              name="price"
                              onChange={handlePriceChange}
                              onBlur={handleBlur}
                              label={t("price_with_currency", {
                                currencySymbol: "€",
                              })}
                              type="number"
                              ref={ref}
                              value={displayValue}
                              step="0.01"
                              min="0"
                              mandatory={true}
                            />
                          );
                        }}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="category"
                        rules={{
                          required: {
                            value: true,
                            message: t("field_required"),
                          },
                        }}
                        renderInput={({ onChange, value }) => (
                          <DropDown
                            options={categoryOptions}
                            onChange={onChange}
                            name="category"
                            label={t("category")}
                            defaultValue={value}
                            mandatory={true}
                          />
                        )}
                      />
                      <GenericControllerWithInput
                        control={control}
                        name="tracked_service_type"
                        rules={{
                          required: {
                            value: true,
                            message: t("field_required"),
                          },
                        }}
                        renderInput={({ onChange, value }) => (
                          <DropDown
                            options={trackedServiceTypeOptions}
                            onChange={onChange}
                            name="tracked_service_type"
                            label={t("trackedservice_type")}
                            defaultValue={value}
                            mandatory={true}
                          />
                        )}
                      />

                      <GenericControllerWithInput
                        control={control}
                        name="info_finance"
                        renderInput={({ onChange, ref, value }) => (
                          <TextAreaWithRef
                            label={t("trackedservice_info_finance")}
                            name="info_finance"
                            onChange={onChange}
                            ref={ref}
                            value={value}
                          />
                        )}
                      />
                    </>
                  )}

                  <GenericControllerWithInput
                    control={control}
                    name="actual_duration"
                    rules={{
                      required: {
                        value: true,
                        message: t("field_required"),
                      },
                    }}
                    renderInput={({ onChange, ref, value }) => (
                      <InputWithRef
                        name="actual_duration"
                        onChange={onChange}
                        label={t("trackedservice_actual_duration")}
                        type="number"
                        ref={ref}
                        value={value || undefined}
                        mandatory={true}
                      />
                    )}
                  />

                  <GenericControllerWithInput
                    control={control}
                    name="info_ops"
                    renderInput={({ onChange, ref, value }) => (
                      <TextAreaWithRef
                        label={t("trackedservice_info_ops")}
                        name="info_ops"
                        onChange={onChange}
                        ref={ref}
                        value={value}
                      />
                    )}
                  />
                  <GenericControllerWithInput
                    control={control}
                    name="executed_on"
                    rules={{
                      required: {
                        value: true,
                        message: t("field_required"),
                      },
                    }}
                    renderInput={({ onChange, value }) => {
                      const handleDateChange = (e: any) => {
                        const formattedValue = dayjs(e).format("YYYY-MM-DD");

                        onChange(formattedValue);
                      };
                      return (
                        <>
                          <FormControlLabel
                            labelPlacement="top"
                            disableTypography
                            sx={{
                              display: "flex",
                              margin: "0",
                              alignItems: "start",
                            }}
                            label={
                              <section>
                                {t("executed_on")}
                                <span style={{ color: "red" }}>*</span>
                              </section>
                            }
                            control={
                              <DatePicker
                                className={styles.datePicker}
                                value={value ? dayjs(value) : null}
                                onChange={handleDateChange}
                                slotProps={{
                                  field: {
                                    clearable: true,
                                    onClear: () => {
                                      setValue("executed_on", "");
                                    },
                                  },
                                }}
                              />
                            }
                          />
                        </>
                      );
                    }}
                  />

                  <GenericControllerWithInput
                    control={control}
                    name="executed_by"
                    renderInput={({ onChange, value }) => (
                      <DropDown
                        options={kaerEmployeesOptions}
                        onChange={onChange}
                        name="executed_by"
                        label={t("executed_by")}
                        defaultValue={value}
                        mandatory={true}
                      />
                    )}
                  />
                </>
              )}

              <GenericButton type="submit" className={styles.submitButton}>
                {formMode === "edit" ? t("save") : t("create")}
              </GenericButton>
            </form>
          </Paper>
        </>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </section>
  );
};

export default TrackedServiceCustomCrud;

function getKaerEmployees(setKaerEmployeesOptions: any, setError: any) {
  const additionalUserParameters: any = {};
  additionalUserParameters["impersonate_subject"] =
    "11111111-1111-1111-1111-444444444444";
  additionalUserParameters["is_active"] = "true";

  api.genericApiRequest({
    entity: "user",
    method: "get",
    parametersToRender: {
      additionalUrlParameters: additionalUserParameters,
      depth: "0",
    },

    successHandler: (res: any) => {
      const employeeOptions = res.data.results.map((item: any) => ({
        value: item.person.subject,
        label: item.last_name + ", " + item.first_name,
      }));

      setKaerEmployeesOptions(
        employeeOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))
      );
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}
function getMainCategories(setCategoryOptions: any, setError: any) {
  api.genericApiRequest({
    entity: "mainCategory",
    method: "get",
    successHandler: (res: any) => {
      const mainCategoryOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryOptions(mainCategoryOptions);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}
function getTrackedServiceTypes(
  setTrackedServiceTypeOptions: any,
  setError: any
) {
  api.genericApiRequest({
    entity: "trackedServiceType",
    method: "get",
    successHandler: (res: any) => {
      const trackedServiceTypeOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setTrackedServiceTypeOptions(trackedServiceTypeOptions);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}
function getTrackedServiceTemplates(
  setTrackedServiceTemplateOptions: any,
  setError: any
) {
  api.genericApiRequest({
    entity: "trackedServiceTemplate",
    method: "get",
    successHandler: (res: any) => {
      const trackedServiceTemplateOptions = res.data.results.map(
        (item: any) => ({
          value: item.id,
          label: item.internal_name,
        })
      );
      setTrackedServiceTemplateOptions(trackedServiceTemplateOptions);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}
function getBuildings(setBuildingOptions: any, subject: string, setError: any) {
  const additionalUserParameters: any = {};
  additionalUserParameters["subject"] = subject;
  api.genericApiRequest({
    entity: "building",
    method: "get",
    parametersToRender: {
      additionalUrlParameters: additionalUserParameters,
      depth: "0",
    },

    successHandler: (res: any) => {
      const buildingOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label:
          item.name + ` (${item.street_house}, ${item.zip} ${item?.city} )`,
      }));
      setBuildingOptions(buildingOptions);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

function getTrackedServiceTemplateValues(
  templateId: any,
  setFormValue: any,
  setError: any
) {
  api.genericApiRequest({
    method: "get",
    entity: "trackedServiceTemplate",
    entityId: templateId.value,
    successHandler: (res: any) => {
      const fieldsToUpdate: Array<keyof FormValues> = [
        "description",
        "estimated_duration",
        "price",
        "tracked_service_type",
        "category",
      ];

      Object.entries(res.data).forEach(([key, value]) => {
        if (fieldsToUpdate.includes(key as keyof FormValues)) {
          const finalValue =
            typeof value === "object" && value !== null && "id" in value
              ? (value as { id: any }).id
              : value;

          setFormValue(key as keyof FormValues, finalValue);
        }
      });
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

function postTrackedService(
  method: any,
  submitData: any,
  setError: any,
  successHandler: any,
  entityId?: any
) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const requestParams = {
    method: method,
    entity: "trackedService",
    submitData: submitData,
    successHandler: () => {
      successHandler();
    },
    failHandler: (error: any) => {
      setError(error);
    },
    config: config,
    ...(method === "put" && entityId ? { entityId } : {}),
  };
  api.genericApiRequest(requestParams);
}
