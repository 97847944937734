import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useCallback, useEffect } from "react";

import styles from "./genericConfirmationModal.module.css";
import GenericButton from "../inputs/button/genericButton";
import { t } from "i18next";

type GenericConfirmationModalProps = {
  title?: string;
  message: string;
  confirmButtonText?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const GenericConfirmationModal: FC<GenericConfirmationModalProps> = ({
  title,
  message,
  confirmButtonText,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === "Escape" && onCancel) {
        event.preventDefault();
        onCancel();
      } else if (event.key === "Enter" && onConfirm) {
        event.preventDefault();
        onConfirm();
      }
    },
    [onConfirm, onCancel]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, handleKeyDown]);

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      PaperProps={{ className: styles.confirmationModalWrapper }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.confirmationModalTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={styles.confirmationModalContent}>
        {message}
      </DialogContent>
      <DialogActions className={styles.confirmationModalActionWrapper}>
        <GenericButton variant="outlined" onClick={onCancel}>
          {t("cancel")}
        </GenericButton>
        <GenericButton onClick={onConfirm}>{confirmButtonText}</GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenericConfirmationModal;
