import { FC } from "react";
import CompanyArea from "./headerbar/companyArea";
import PortalNameArea from "./headerbar/portalNameArea";
import "./styles/headerBar.css";
import { AppBar, Toolbar } from "@mui/material";
import HeaderBackground from "../icons/headerBackground";
import { renderToStaticMarkup } from "react-dom/server";
import MenuIcon from "@mui/icons-material/Menu";
import AccountSettings from "./headerbar/accountSettings";
import GenericButton from "./forms/inputs/button/genericButton";

interface Props {
  toggleDrawer: (open: boolean) => any;
  selectedCompany: string;
  handleOpenSettings?: (event: any) => void;
  handleCloseSettings?: (event: any) => void;
  handleNavigateSettings?: () => void;
  combinedSignout: () => void;
  signOut: () => void;
  user?: any;
  email?: any;
  openSettings: boolean;
  firstNameInitial?: string;
  lastNameInitial?: string;
  anchorEl?: any;
}

const HeaderBar: FC<Props> = ({
  toggleDrawer,
  selectedCompany,
  handleOpenSettings,
  handleCloseSettings,
  handleNavigateSettings,
  combinedSignout,
  signOut,
  openSettings,
  firstNameInitial,
  lastNameInitial,
  anchorEl,
  user,
  email,
}) => {
  const svgString = encodeURIComponent(
    renderToStaticMarkup(<HeaderBackground />)
  );
  return (
    <>
      <AppBar position="static" className="appBarContainer">
        <Toolbar
          className="toolbarContainer"
          sx={{
            background: `url('data:image/svg+xml;utf8, ${svgString}')`,
          }}
        >
          <GenericButton
            onClick={toggleDrawer(true)}
            variant="icon"
            color="white"
            className="menuButton"
          >
            <MenuIcon />
          </GenericButton>
          <div className="headerBarTextContainer">
            <CompanyArea companyName={selectedCompany} />
            <PortalNameArea />
          </div>
          <AccountSettings
            handleOpenSettings={handleOpenSettings}
            handleCloseSettings={handleCloseSettings}
            handleNavigateSettings={handleNavigateSettings}
            combinedSignout={combinedSignout}
            signOut={signOut}
            open={openSettings}
            firstNameInitial={firstNameInitial}
            lastNameInitial={lastNameInitial}
            anchorEl={anchorEl}
            user={user}
            email={email}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderBar;
