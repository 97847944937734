import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { store } from "../components/state/store";
import { setParent } from "../components/state/parent/parentSlice";
import { t } from "i18next";

type UrlParameters = {
  entity?: string;
  method: AxiosRequestConfig["method"];
  responseType?: ResponseType;
  successHandler?: any;
  failHandler?: any;
  entityId?: any;
  submitData?: any;
  config?: any;
  additionalRouteParts?: object;
  getAllPages?: boolean;
  shouldNotImpersonate?: boolean;
  parametersToRender?: {
    additionalUrlParameters?: object;
    depth?: string;
    ordering?: any;
    limit?: any;
    offset?: any;
    search?: any;
    [key: string]: any;
  };
};

const baseURL = "/api/v1/";

const genericRequest = (parameters: UrlParameters) => {
  let requestURL = baseURL + parameters.entity + "/";
  const selectedChildCompany = store.getState().parent.subjectId;
  if (parameters.entity === "logout") {
    store.dispatch(
      setParent({
        subjectId: "",
        companyId: "",
        companyName: "",
      })
    );
  }

  let parametersToRender: any = {};
  let additionalRouteParts: any = {};

  if (parameters.parametersToRender) {
    parametersToRender = parameters.parametersToRender;
  }
  if (parameters.additionalRouteParts) {
    additionalRouteParts = parameters.additionalRouteParts;
  }

  if (parameters.entityId) {
    requestURL += parameters.entityId + "/";
  }

  if (selectedChildCompany !== "" && !parameters?.shouldNotImpersonate) {
    parametersToRender["impersonate_subject"] = selectedChildCompany;
  }

  if (parametersToRender.additionalUrlParameters) {
    const { additionalUrlParameters, ...rest } = parametersToRender;
    const finalObject = { ...additionalUrlParameters, ...rest };
    parametersToRender = finalObject;
  }

  let routeParts: any = "";
  for (const key in additionalRouteParts) {
    routeParts += key + additionalRouteParts[key];
  }

  let renderedParameters: any = "";
  for (const key in parametersToRender) {
    if (renderedParameters === "") {
      renderedParameters += "?";
    } else {
      renderedParameters += "&";
    }

    renderedParameters += key + "=" + parametersToRender?.[key];
  }

  requestURL += routeParts + renderedParameters;
  doApiRequest(parameters, requestURL);
};

const doApiRequest = (
  parameters: any,
  requestURL: string,
  previousData?: any
) => {
  axios({
    method: parameters.method,
    url: requestURL,
    data: parameters.submitData,
    headers: parameters.config?.headers,
    ...(parameters.responseType && { responseType: parameters.responseType }),
  })
    .then((res) => {
      if (res.status === 200 || res.status === 204 || res.status === 201) {
        if (parameters.getAllPages === true) {
          if (previousData) {
            res.data.results = res.data.results.concat(previousData);
          }
          if (res.data.next && res.data.next !== null) {
            doApiRequest(parameters, res.data.next, res.data.results);
          } else {
            parameters.successHandler(res);
          }
        } else parameters.successHandler(res);
      }
    })
    .catch((error: any) => {
      let message;
      let errorData;
      if (error?.response?.status === 401 && parameters.entity !== "login") {
        message = "logout";
      } else if (error.response && error.response.data) {
        errorData = error.response.data;

        if (
          typeof errorData === "object" &&
          Object.keys(errorData).length > 0 &&
          error?.response?.status !== 500
        ) {
          const combinedErrors = Object.entries(errorData).reduce(
            (acc, [fieldname, errorMessage]) => {
              return { ...acc, [fieldname]: errorMessage };
            },
            {}
          );
          message = combinedErrors;
        } else {
          message = t("generic_error_message", {
            error: error?.response?.status,
          });
        }
      }
      parameters.failHandler?.(message, error?.response?.status);
    });
};
//---------------------------------------------------------------------------------------------------------
export class api {
  public static genericApiRequest = (parameters: UrlParameters) => {
    genericRequest({
      entity: parameters.entity,
      shouldNotImpersonate: parameters.shouldNotImpersonate,
      method: parameters.method,
      responseType: parameters.responseType,
      successHandler: parameters.successHandler,
      failHandler: parameters.failHandler,
      entityId: parameters.entityId,
      submitData: parameters.submitData,
      config: parameters.config,
      parametersToRender: parameters.parametersToRender,
      additionalRouteParts: parameters.additionalRouteParts,
      getAllPages: parameters.getAllPages,
    });
  };
}
