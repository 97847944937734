import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./genericButton.module.css";
import { CircularProgress } from "@mui/material";

export type ButtonVariant = "contained" | "outlined" | "icon";

export type ButtonColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "iconBase"
  | "white"
  | "error";

type ButtonSize = "small" | "medium" | "large";

interface GenericButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id?: any;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  children?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  onClick?: (event: any) => void | Promise<void>;
  className?: string; // for adding custom styles
  type?: "button" | "submit" | "reset";
  waitingForResponse?: boolean; //default false - need to be handed in true for disabling buutton after click
}

const GenericButton = forwardRef<HTMLButtonElement, GenericButtonProps>(
  (
    {
      id,
      variant = "contained",
      color = "primary",
      size = "medium",
      children,
      startIcon,
      endIcon,
      disabled = false,
      onClick,
      className,
      type = "button",
      waitingForResponse = false,
      ...rest
    },
    ref
  ) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

    useEffect(() => {
      setIsDisabled(disabled);
    }, [disabled]);

    const handleClick = async (event: any) => {
      if (onClick) {
        if (waitingForResponse) {
          setIsDisabled(true);
          try {
            const result = onClick(event);

            if (result instanceof Promise) {
              await result;
            }
          } finally {
            setIsDisabled(false);
          }
        } else {
          onClick(event);
        }
      }
    };

    return (
      <button
        ref={ref}
        className={classNames(
          styles.btn,
          styles[`btn--${variant}`],
          styles[`btn--${color}`],
          styles[`btn--${size}`],
          { [styles["btn--disabled"]]: isDisabled },
          className
        )}
        onClick={(event: any) => handleClick(event)}
        disabled={isDisabled}
        type={type}
        id={id}
        {...rest}
      >
        {startIcon && (
          <span
            className={classNames(styles["btn__icon--start"], {
              [styles[`btn__icon--${color}`]]:
                variant === "outlined" && !isDisabled,
              [styles["btn__icon--white"]]:
                variant === "contained" && !isDisabled,
              [styles["btn__icon--disabled"]]: isDisabled,
              [styles["btn--icon"]]: variant === "icon",
            })}
          >
            {startIcon}
          </span>
        )}

        <span className={styles.btn__content}>
          {waitingForResponse && isDisabled && (
            <span className={styles.btn_disabled_spinnerWrapper}>
              <CircularProgress size={20} className={styles.btn_spinner} />
            </span>
          )}

          <span
            className={classNames({
              [styles.btn_disabled_invisibleChildren]:
                waitingForResponse && isDisabled,
            })}
          >
            {children}
          </span>
        </span>
        {endIcon && (
          <span
            className={classNames(styles["btn__icon--end"], {
              [styles[`btn__icon--${color}`]]:
                variant === "outlined" && !isDisabled,
              [styles["btn__icon--white"]]:
                variant === "contained" && !isDisabled,
              [styles["btn__icon--disabled"]]: isDisabled,
            })}
          >
            {endIcon}
          </span>
        )}
      </button>
    );
  }
);

GenericButton.displayName = "GenericButton";

export default GenericButton;
