import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import styles from "./subjectOverviewCard.module.css";
import GenericButton from "../forms/inputs/button/genericButton";

interface Props {
  formValues?: any;
  subjectName?: any;
}

const SubjectOverviewCard: FC<Props> = ({ formValues, subjectName }) => {
  const [isLoading, setIsLoading] = useState<any>(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (formValues && formValues !== undefined && formValues !== null) {
      setIsLoading(false);
    }
  }, [formValues]);

  const formattedValues: any = (item: any) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let tempValue = "";

    if (item !== undefined && item !== null) {
      tempValue = item;
      if (Array.isArray(item)) {
        tempValue = item.join(", ");
      } else if (regex.test(item)) {
        const [year, month, day] = item.split("-");
        const newFormat = `${day}.${month}.${year}`;
        tempValue = newFormat;
      }
    }

    return tempValue;
  };

  const renderContent = () => {
    const mdFormSet = formValues.measurementdefinitiontoform_set;
    return mdFormSet.map((item: any) => {
      const emailMeasurements = [
        "stammdaten_basis_unternehmen_betriebsmedizin_email",
        "stammdaten_basis_email_unternehmen_arbeitsschutz",
      ];

      const renderedMetricValue = (metricValue: any, measurementName?: any) => {
        if (emailMeasurements.includes(measurementName)) {
          const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
          const emailAddresses: any = metricValue?.match(emailRegex);

          const capitalizeWords = (label: string) => {
            return label.replace(/\b\w/g, (char) => char.toUpperCase());
          };

          const chipLabel = (email: string) => {
            const emailParts = email.split("@")[0];
            const cleanedLabel = emailParts.replace(/[._]/g, " ");
            const trimmedLabel = cleanedLabel.replace(/\s+/g, " ").trim();
            const capitalizedLabel = capitalizeWords(trimmedLabel);

            return capitalizedLabel.length > 20
              ? `${capitalizedLabel.slice(0, 20)}...`
              : capitalizedLabel;
          };

          if (emailAddresses !== undefined && emailAddresses !== null) {
            return (
              <>
                {emailAddresses?.map((emailAdress: any, index: any) => (
                  <Tooltip key={index} title={emailAdress} placement="top">
                    <a
                      className={styles.emailLink}
                      href={`mailto:${emailAdress}`}
                    >
                      <Chip
                        label={chipLabel(emailAdress)}
                        sx={{
                          width: "fit-content",
                          backgroundColor: "#8c1ec8",
                          color: "#FFFFFF",
                        }}
                      />
                    </a>
                  </Tooltip>
                ))}
              </>
            );
          } else {
            return <span></span>;
          }
        }
        return <span>{formattedValues(metricValue)}</span>;
      };
      return (
        <Grid
          key={item.measurement_definition?.name}
          item
          component={ListItem}
          xs={3}
          className={styles.noPadding}
        >
          <ListItemText
            primary={item.measurement_definition?.label}
            secondary={renderedMetricValue(
              item?.measurement_definition?.metric_value,
              item?.measurement_definition?.name
            )}
            primaryTypographyProps={{ className: styles.primaryText }}
            secondaryTypographyProps={{
              className: styles.secondaryText,
              component: "div",
            }}
          />
        </Grid>
      );
    });
  };

  const renderNoContentAvailable = () => {
    return (
      <Typography
        variant="h5"
        component="div"
        className={styles.noDataAvailableText}
        gutterBottom
      >
        {t("subject_overview_no_data_available", { subject: subjectName })}
      </Typography>
    );
  };
  const heading = () => {
    let tempHeading: any = <></>;
    if (Object.keys(formValues).length !== 0) {
      tempHeading = <h5>{t("overview")}</h5>;
    }
    return tempHeading;
  };

  const navigateToCoredata = () => {
    navigate("/admin/coreData");
  };

  return (
    <Paper className={styles.paperContainer}>
      <>
        <div
          className={`
            ${styles.flexContainer}
            ${
              Object.keys(formValues).length !== 0
                ? styles.justifySpaceBetween
                : styles.justifyEnd
            }
          `}
        >
          <>{heading()}</>
          <Tooltip title={t("edit_coredata")} placement="top">
            <GenericButton variant="icon" onClick={navigateToCoredata}>
              <EditIcon />
            </GenericButton>
          </Tooltip>
        </div>
        {!isLoading && (
          <>
            {Object.keys(formValues).length !== 0 ? (
              <Grid container component={List} className={styles.noPadding}>
                {renderContent()}
              </Grid>
            ) : (
              <Grid
                container
                className={`${styles.noPadding} ${styles.justifyCenter}`}
              >
                {renderNoContentAvailable()}
              </Grid>
            )}
          </>
        )}
      </>
    </Paper>
  );
};

export default SubjectOverviewCard;
