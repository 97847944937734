export function convertEurocentToEuroForLabels(cents: number) {
  const euros = cents / 100;
  return euros.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
}

export const euroToEurocent = (euro: any): number => {
  return Math.round(Number(parseFloat(euro).toFixed(2)) * 100);
};

export const eurocentToEuro = (eurocent: any) => {
  return eurocent / 100;
};

export function formatFilesize(filesize: number) {
  const kiloBytes = 1024;
  const megaBytes = kiloBytes * 1024;

  if (filesize < megaBytes) {
    return (filesize / kiloBytes).toFixed(2) + " KB";
  } else {
    return (filesize / megaBytes).toFixed(2) + " MB";
  }
}
