import { Grid } from "@mui/material";
import { FC, useState } from "react";
import { api } from "../../helper/api";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import styles from "./appointmentTreeItemActionButtons.module.css";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  appointment: any;
  appointmentId: string;
  handleSetLoading: (loading: boolean) => void;
  handleSetError: (error: any) => void;
  handleSetCurrentAppointment: (appointment: any) => void;
  allButtonsDisabled?: boolean;
}

const AppointmentTreeItemActionButtons: FC<Props> = ({
  appointment,
  appointmentId,
  handleSetLoading,
  handleSetError,
  handleSetCurrentAppointment,
  allButtonsDisabled,
}) => {
  const [modalError, setModalError] = useState<string | null>(null);
  const { showConfirmationModalAsync, showConfirmationModal } =
    useConfirmationModal();

  const handleError = (error: any) => {
    handleSetError(error);
    setModalError(error);
  };

  const navigate = useNavigate();
  let appointmentActionsButtons: any = <></>;

  const loadAppointment = (
    appointmentId: any,
    status?: string,
    onSuccessHandler?: any
  ) => {
    const additionalRoutePartsOnSuccessHandlerCall: any = {};
    additionalRoutePartsOnSuccessHandlerCall["dashboard"] = "/";

    if (
      status &&
      (status === "review_preparation_required" ||
        status === "post_processing_required")
    ) {
      const additionalRouteParts: any = {};

      additionalRouteParts["wait_for_all_tasks_to_complete"] = "/";
      api.genericApiRequest({
        entity: "appointment",
        method: "get",
        entityId: appointmentId,
        additionalRouteParts: additionalRouteParts,
        successHandler: () => {
          api.genericApiRequest({
            entity: "appointment",
            method: "get",
            entityId: appointmentId,
            additionalRouteParts: additionalRoutePartsOnSuccessHandlerCall,
            successHandler: (res: any) => {
              handleSetCurrentAppointment(res.data);
              if (onSuccessHandler) {
                onSuccessHandler(res.data);
              }
              handleSetLoading(false);
            },
            failHandler: (error: any) => {
              handleError(error);
            },
          });
        },
      });
    } else {
      api.genericApiRequest({
        entity: "appointment",
        method: "get",
        entityId: appointmentId,
        additionalRouteParts: additionalRoutePartsOnSuccessHandlerCall,
        successHandler: (res: any) => {
          handleSetCurrentAppointment(res.data);

          if (onSuccessHandler) {
            onSuccessHandler(res.data);
          }
          handleSetLoading(false);
        },
        failHandler: (error: any) => {
          handleError(error);
        },
      });
    }
  };

  const renderAppointmentActionButtons = () => {
    const buttons = [];
    const additionalRouteParts: any = {};
    additionalRouteParts["set_status"] = "/";

    const startReviewButton = (
      <GenericButton
        key={appointment.id + "open/in_progress"}
        color="secondary"
        disabled={allButtonsDisabled}
        className={styles.button}
        onClick={async () => {
          const requestData = {
            status: "review_preparation_required",
          };
          const isConfirmed = await showConfirmationModalAsync(
            t("appointment_confirm_review_status")
          );

          if (!isConfirmed) return;

          handleSetLoading(true);

          api.genericApiRequest({
            entity: "appointment",
            method: "put",
            successHandler: () => {
              loadAppointment(appointmentId, "review_preparation_required");
            },
            failHandler: (error: any) => {
              handleSetError(error);
            },
            entityId: appointment.id,
            additionalRouteParts: additionalRouteParts,
            parametersToRender: {
              depth: "0",
            },
            submitData: requestData,
          });
        }}
      >
        {t("dashboard_start_review")}
      </GenericButton>
    );
    const backToEditButton = (
      <GenericButton
        key={appointment.id + "waiting_for_review"}
        disabled={allButtonsDisabled}
        className={styles.button}
        onClick={async () => {
          const requestData = {
            status: "in_progress",
          };
          const isConfirmed = await showConfirmationModalAsync(
            t("appointment_confirm_back_to_progress")
          );
          if (!isConfirmed) return;

          handleSetLoading(true);

          api.genericApiRequest({
            entity: "appointment",
            method: "put",
            successHandler: () => {
              loadAppointment(appointmentId);
            },
            failHandler: (error: any) => {
              handleSetError(error);
            },
            entityId: appointment.id,
            additionalRouteParts: additionalRouteParts,
            submitData: requestData,
          });
        }}
      >
        {t("dashboard_back_to_edit")}
      </GenericButton>
    );
    const createDocumentsButton = (
      <GenericButton
        color="secondary"
        className={styles.button}
        disabled={allButtonsDisabled}
        key={appointment.id + "review_preparation_required"}
        onClick={async () => {
          const requestData = {
            status: "review_preparation_required",
          };
          const isConfirmed = await showConfirmationModalAsync(
            t("appointment_confirm_create_documents")
          );
          if (!isConfirmed) return;

          handleSetLoading(true);
          api.genericApiRequest({
            entity: "appointment",
            method: "put",
            successHandler: () => {
              loadAppointment(appointmentId, "review_preparation_required");
            },
            failHandler: (error: any) => {
              handleSetError(error);
            },
            entityId: appointment.id,
            additionalRouteParts: additionalRouteParts,
            submitData: requestData,
          });
        }}
      >
        {t("dashboard_create_documents")}
      </GenericButton>
    );
    const finishAppointmentButton = (
      <GenericButton
        key={appointment.id + "post_processing_required"}
        className={styles.button}
        disabled={allButtonsDisabled}
        color="error"
        onClick={async () => {
          if (!appointment?.subject?.is_registered) {
            showConfirmationModal(
              t("appointment_set_complete_error_user_not_registered")
            );
            return;
          }
          const requestData = {
            status: "post_processing_required",
          };
          const isConfirmed = await showConfirmationModalAsync(
            "Möchtest du das Review für diesen Termin wirklich abschließen und die Dokumente für den Kunden freigeben?"
          );
          if (!isConfirmed) return;

          handleSetLoading(true);

          api.genericApiRequest({
            entity: "appointment",
            method: "put",
            successHandler: () => {
              loadAppointment(appointmentId, "post_processing_required");
            },
            failHandler: (error: any) => {
              handleError(error);
            },
            entityId: appointment.id,
            additionalRouteParts: additionalRouteParts,
            parametersToRender: {
              depth: "0",
            },
            submitData: requestData,
          });
        }}
      >
        {t("dashboard_finish_appointment")}
      </GenericButton>
    );

    const completedCardInfo = (
      <GenericButton
        key={"appointment_completed"}
        className={styles.cardButton}
        onClick={() => navigate("/appointmentDashboard")}
      >
        {t("appointment_completed")}
      </GenericButton>
    );
    if (
      ["open", "in_progress", "prefilled_by_user"].includes(appointment.status)
    ) {
      buttons.push(startReviewButton);
    }

    if (
      appointment.status === "review_preparation_required" ||
      appointment.status === "waiting_for_review"
    ) {
      buttons.push(
        backToEditButton,
        createDocumentsButton,
        finishAppointmentButton
      );
    }

    if (appointment.status === "post_processing_required") {
      buttons.push(backToEditButton, finishAppointmentButton);
    }

    if (appointment.status === "completed") {
      buttons.push(completedCardInfo);
    }

    return buttons;
  };
  if (appointment) {
    appointmentActionsButtons = renderAppointmentActionButtons();
  }

  return (
    <>
      <Grid className={styles.buttonsWrapper}>{appointmentActionsButtons}</Grid>
      {modalError && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={modalError}
          onClosehandler={() => setModalError(null)}
        />
      )}
    </>
  );
};

export default AppointmentTreeItemActionButtons;
