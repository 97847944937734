import { ListItemIcon, MenuItem } from "@mui/material";

export const FileTableRowActionMenuItem = ({
  onClick,
  icon,
  label,
  disabled = false,
}: {
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
}) => (
  <MenuItem onClick={onClick} disabled={disabled} sx={{ margin: "0" }}>
    <ListItemIcon>{icon}</ListItemIcon>
    {label}
  </MenuItem>
);
