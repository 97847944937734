import { FC } from "react";
import RoundedContentBox from "../general/roundedContentBox";
import { Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import UploadIcon from "../../icons/uploadIcon";
import { t } from "i18next";
import ReportsSampleIcon from "../../icons/reportsSampleIcon";
import styles from "./simpleFolderCard.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Props {
  name?: any;
  id?: any;
  onClickHandler: (id: string, name: string) => void;
  showCursor?: boolean;
  folderId?: any;
  fileCount?: any;
}

const SimpleFolderCard: FC<Props> = ({
  name,
  id,
  onClickHandler,
  showCursor,
  folderId,
  fileCount,
}) => {
  let icon = <></>;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  switch (name) {
    case "upload":
      icon = <UploadIcon height="3rem" width="3rem" />;
      break;
    case "Berichte von kaer":
      icon = <ReportsSampleIcon height="2rem" width="2rem" />;
      break;
  }
  return (
    <Grid
      item
      xs={12}
      onClick={() => onClickHandler(id, name)}
      className={styles.container}
    >
      <RoundedContentBox
        padding={isMobile ? "0" : "0.5rem 1.5rem"}
        enableHover={true}
        showCursor={showCursor}
      >
        <ListItem
          className={styles.listItem}
          sx={{
            "&& .MuiTypography-root": {
              fontWeight: id === folderId ? "bold" : "",
            },
            "&& .MuiListItemSecondaryAction-root": {
              color: "rgba(0, 0, 0, 0.6)",
            },
          }}
          secondaryAction={
            <span>{t("fileitemcount", { count: fileCount })}</span>
          }
        >
          <ListItemIcon className={styles.listItemIcon}>{icon}</ListItemIcon>
          <ListItemText
            primary={capitalizeFirstLetter(name)}
            className={
              name === "Berichte von Kaer" ? styles.caseBerichtePadding : ""
            }
          />
        </ListItem>
      </RoundedContentBox>
    </Grid>
  );
};

export default SimpleFolderCard;
