import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import DefaultInput from "../forms/inputs/defaultInput";
import CustomErrorMessage from "../forms/inputsWithRef/customErrorMessage";
import styles from "./dndCollapsible.module.css";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";

interface Props {
  visibilityRuleset?: any;
  mandatory?: boolean;
  onHandleCheckboxChange?: () => void;
  onHandleRulesetChange?: (event: any, id?: any) => void;
  id?: any;
  visibility_ruleset?: any;
}

export function DnDCollapsible(props: Props) {
  const [ruleset, setRuleset] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [disableMandatoryField, setDisableMandatoryField] = useState<boolean>(false);

  useEffect(() => {
    if (props.visibility_ruleset) {
      let visibilityRulesetString: string;

      if (typeof props.visibility_ruleset === "string") {
        visibilityRulesetString = props.visibility_ruleset;
      } else {
        visibilityRulesetString = JSON.stringify(props.visibility_ruleset);
      }

      setRuleset(visibilityRulesetString);
      setDisableMandatoryField(visibilityRulesetString.trim() !== "{}");
    } else {
      setRuleset("{}");
      setDisableMandatoryField(false);
    }
  }, [props.visibility_ruleset]);

  const handleRulesetChange = (event: any) => {
    setRuleset(event.target.value);
  };

  useEffect(() => {
    if (props.onHandleRulesetChange) {
      if (validateJson()) {
        props.onHandleRulesetChange(ruleset, props.id);
      }
    }
  }, [ruleset]);

  const validateJson = () => {
    try {
      JSON.parse(ruleset);
    } catch (e) {
      setError(true);
      setErrorMessage(e);
      return false;
    }
    setError(false);
    return true;
  };

  return (
    <Accordion
      className={styles.accordion}
      disableGutters={true}
      classes={{ expanded: styles.accordionExpanded }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ expanded: styles.accordionSummaryExpanded }}
        data-testid="dnd-collapsible"
      >
        <span>{t("settings")}</span>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.wrapper}>
          <Tooltip
            title={
              props.mandatory
                ? t("form_is_mandatory_cannot_add_visibility_rule")
                : ""
            }
            arrow
            placement="top"
            disableHoverListener={!props.mandatory}
          >
            <span>
              <DefaultInput
                label="Visibility"
                infoTooltip={true}
                infoTooltipMessage={
                  'Beispiele: {"VARIABLENNAME": {"is": "yes"}} | {"VARIABLENNAME": {"not": "no"}} | {"VARIABLENNAME": {"gt": 0.5}} | {"VARIABLENNAME": {"lt": 1.4}} | {"VARIABLENNAME": {"in": [0.1, 0.2, 5.0]}} | {"VARIABLENNAME": {"nin": ["Heuschnupfen", "Ausschlag"]}} | {"VARIABLENNAME": {"contains": "Heuschnupfen"}}'
                }
                value={ruleset}
                onChange={handleRulesetChange}
                onBlur={validateJson}
                disabled={props.mandatory}
              />
            </span>
          </Tooltip>
          {error && (
            <CustomErrorMessage
              error={error}
              errorMessage={errorMessage.message}
            />
          )}

          {/* <label className={styles.visibilityInputStyle}>
            Visibility
            <input
              defaultValue={props.visibilityRuleset}
              value={ruleset}
              onChange={handleRulesetChange}
            />
          </label> */}
          <Tooltip
            title={
              disableMandatoryField
                ? t("visibility_ruleset_present_cannot_check_box")
                : ""
            }
            arrow
            placement="bottom"
            disableHoverListener={!disableMandatoryField}
          >
            <label className={styles.mandatoryCheckbox}>
              {t("mandatory_field")}
              <input
                type="checkbox"
                checked={props.mandatory}
                disabled={disableMandatoryField}
                onChange={props.onHandleCheckboxChange}
              />
            </label>
          </Tooltip>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
