import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MeasurementDefinitionSelect from "./measurementDefinitionSelect";
import { useTranslation } from "react-i18next";
import DefaultForm from "../forms/defaultForm";
import styles from "./createMeasurementDefinition.module.css";
import GenericButton from "../forms/inputs/button/genericButton";
import AddIcon from "@mui/icons-material/Add";
import { useConfirmationModal } from "../../context/confirmationModalContext";

const CreateMeasurementDefinition: FC = () => {
  const [selectInputCount, setSelectInputCount] = useState<any>([]);
  const [selectValues, setSelectValues] = useState<any>([]);
  const [predefinedTypeSelectValues, setPredefinedTypeSelectValues] =
    useState<any>([]);
  const [defaultValueCounter, setDefaultValueCounter] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);
  const [templateUsage, setTemplateUsage] = useState<any>([]);

  const { measurementDefinitionId } = useParams() as {
    measurementDefinitionId: string;
  };
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const unitOptions = [
    { label: " - ", value: "" },
    { label: "Centimeter", value: "cm" },
    { label: "Kilogramm", value: "Kg" },
    { label: "Gramm", value: "g" },
    { label: "Grad Celsius", value: "c" },
    { label: "Prozent", value: "percent" },
  ];

  const typeOptions = [
    { label: "Ja/Nein", value: "bool" },
    {
      label: "Ja/Nein/Keine Angabe",
      value: "boolNoStatement",
    },
    { label: "Auswahl", value: "choice" },
    { label: "Mehrfachauswahl", value: "multiselect" },
    { label: "Datum", value: "date" },
    { label: "Kommazahl", value: "float" },
    { label: "Zahl", value: "int" },
    { label: "Text", value: "string" },
    { label: "mehrzeiliger Text", value: "text" },
  ];

  const tooltip = (
    <ul className={styles.tooltipList}>
      <li>{t("use_lowercase")}</li>
      <li>{t("use_no_umlauts")}</li>
      <li>
        {t("use_no_spaces")}
        <br /> {t("use_underscore")}
      </li>
    </ul>
  );

  // get Data from DefaultForm; If Edit/MeasurementDefinition setValues
  const prepareMeasurementDefinitionData = (data: any) => {
    if (data.length !== 0) {
      if (measurementDefinitionId) {
        let typeSelectOptions: any = [];
        const selectValues = data.value_map;

        if (Array.isArray(selectValues) && selectValues.length !== 0) {
          typeSelectOptions = selectValues.map((obj: any) => {
            const [label, value] = Object.entries(obj)[0];

            return { value: value, label: label };
          });

          setVisible(true);
        }
        setPredefinedTypeSelectValues(typeSelectOptions);
        setTemplateUsage(data.template_usage);
      }
    }
  };
  const preSubmitHandler = (data: any) => {
    const arrayToList = selectValues.map(({ label, value }: any) =>
      value ? { [label]: value } : { [label]: label }
    );

    const reuseResult = () => {
      if (data.reuse === "yes") {
        return true;
      }

      return false;
    };

    const result = {
      name: data.name.toLocaleLowerCase(),
      label: data.label,
      short_label: data.short_label,
      type: data.type,
      unit:
        data?.unit !== undefined || data?.unit === 0 || data?.unit === "0"
          ? data?.unit
          : "",
      reuse: reuseResult(),
      value_map: arrayToList,
      sample_data: data?.sample_data,
      observation: data?.observation,
      assessment: data?.assessment,
      dangerous_answer: data?.dangerous_answer,
      possible_remedy: data?.possible_remedy,
      danger_potential: data?.danger_potential,
    };

    return result;
  };

  const addSelectInput = () => {
    const newInputId = String(selectInputCount.length);
    const defaultValue = String(defaultValueCounter);

    setDefaultValueCounter((prevCounter) => prevCounter + 1);

    setSelectInputCount((prev: any) => [...prev, { id: newInputId }]);
    setSelectValues((prev: any) => [
      ...prev,
      { id: newInputId, label: "", value: defaultValue },
    ]);
  };

  // delete inputs if type === choice | multiselect
  const deleteSelectedInput = async (
    id: string | undefined,
    skipQuestion = false
  ) => {
    const item = selectValues.filter((item: any) => item.id === id);

    const newItems = selectInputCount.filter((item: any) => item.id !== id);
    const newValues = selectValues.filter((item: any) => item.id !== id);

    const label = item.length !== 0 ? item[0].label : "den Eintrag";

    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("confirm_delete_label", { label })
      );
      if (!isConfirmed) return;
    }
    setSelectInputCount(newItems);
    setSelectValues(newValues);
  };

  const handleSelectValues = (
    id: string | undefined | any,
    value: string,
    label: string
  ) => {
    setSelectValues((prevValues: any) => {
      const index = prevValues.findIndex((item: any) => item.id === id);

      if (index > -1) {
        const updatedValues = [...prevValues];
        updatedValues[index] = { ...updatedValues[index], value, label };
        return updatedValues;
      } else {
        return [...prevValues, { id, value, label }];
      }
    });
  };

  useEffect(() => {
    if (predefinedTypeSelectValues) {
      const items: any = [];
      let maxExistingValue = 0;

      for (const key in predefinedTypeSelectValues) {
        const index = key;
        const defaultLabel = predefinedTypeSelectValues[key].label;
        const defaultValue = predefinedTypeSelectValues[key].value;

        const numericValue = parseInt(defaultValue, 10);
        if (!isNaN(numericValue) && numericValue >= maxExistingValue) {
          maxExistingValue = numericValue + 1;
        }

        items.push({
          id: index,
          label: defaultLabel,
          value: defaultValue,
        });
      }

      setSelectInputCount(items);
      setSelectValues(items);
      setDefaultValueCounter(maxExistingValue);
    }
  }, [predefinedTypeSelectValues]);

  const customVisibilityChange = (data: any) => {
    if (
      data.target.value.value === "choice" ||
      data.target.value.value === "multiselect"
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  //visualize Templates in which the MD is already used
  const renderTemplates = () => {
    if (templateUsage.length !== 0) {
      return (
        <div className={styles.templateList}>
          <h5>{t("templates")}</h5>
          <ul>
            {templateUsage.map((item: any) => (
              <li key={item.name}>{item.name}</li>
            ))}
          </ul>
        </div>
      );
    }
  };
  const additionalUrlParameters: any = {};
  additionalUrlParameters["with_template_usage"] = "true";
  additionalUrlParameters["test_for_measurement_usage"] = "true";

  const isEditAllowed = (data: any) => {
    if (data.is_used_in_measurement) {
      setIsDisabled(true);
    }
  };

  // Function to move an item up/down for the later saved valueMap array
  const moveUp = (index: any) => {
    if (index === 0) return;
    const newMap = [...selectInputCount];
    [newMap[index - 1], newMap[index]] = [newMap[index], newMap[index - 1]];

    const newValues = [...selectValues];
    [newValues[index - 1], newValues[index]] = [
      newValues[index],
      newValues[index - 1],
    ];

    setSelectInputCount(newMap);
    setSelectValues(newValues);
  };

  const moveDown = (index: any) => {
    if (index === selectInputCount.length - 1) return;
    const newMap = [...selectInputCount];
    [newMap[index], newMap[index + 1]] = [newMap[index + 1], newMap[index]];

    const newValues = [...selectValues];
    [newValues[index], newValues[index + 1]] = [
      newValues[index + 1],
      newValues[index],
    ];

    setSelectInputCount(newMap);
    setSelectValues(newValues);
  };

  return (
    <>
      <DefaultForm
        withPaper={true}
        entity="measurementDefinition"
        entityId={measurementDefinitionId}
        targetEntity="measurementDefinition"
        targetEntityId={measurementDefinitionId}
        dataCallback={prepareMeasurementDefinitionData}
        preSubmitHandler={preSubmitHandler}
        cancelButton={true}
        onSuccessHandler={() => navigate("/settings/measurementDefinitionList")}
        additionalUrlParameters={additionalUrlParameters}
        //--------------------------------
        onCancelFormHandler={() =>
          navigate("/settings/measurementDefinitionList")
        }
        isEditAllowed={isEditAllowed}
        input={[
          {
            name: "name",
            label: t("name"),
            pattern: /^[a-z0-9_]*$/,
            autoComplete: "off",
            tooltip: true,
            tooltipMessage: tooltip,
            placement: "right",
            error: "error",
            // readOnly: true,
            disabled: isDisabled,
            required: true,
            errorMessageValidation: t(
              "error_msg_invalid_characters_change_your_input"
            ),
          },
          {
            name: "label",
            label: t("description"),
            autoComplete: "off",
            required: true,
            error: "error",
            errorMessageRequired: t("error_msg_please_add_description"),
          },
          {
            name: "short_label",
            label: t("short_label"),
            autoComplete: "off",
            required: false,
            error: "error",
            // errorMessageRequired: t("error_msg_please_add_description"),
          },
          {
            type: "choice",
            name: "type",
            label: t("type"),
            options: typeOptions,
            isClearable: true,
            required: true,
            onDropdownChange: customVisibilityChange,
            errorMessageRequired: t("error_msg_please_select_type"),
            error: "error",
          },
          {
            type: "custom",
            name: "add_choice",
            label: "add_choice",
            visible: visible,
            customElement: (
              <>
                <GenericButton onClick={addSelectInput} startIcon={<AddIcon />}>
                  {t("add_choice")}
                </GenericButton>

                <div className={styles.selectInputWrapper}>
                  {selectInputCount.map((item: any, index: any) => (
                    <MeasurementDefinitionSelect
                      id={item.id}
                      key={item.id}
                      onChange={handleSelectValues}
                      onDelete={deleteSelectedInput}
                      index={index}
                      selectedInputCount={selectInputCount}
                      moveUp={moveUp}
                      moveDown={moveDown}
                      label={
                        selectValues.find((val: any) => val.id === item.id)
                          ?.label || ""
                      }
                      value={
                        selectValues.find((val: any) => val.id === item.id)
                          ?.value || "0"
                      }
                    />
                  ))}
                </div>
              </>
            ),
          },
          {
            type: "choice",
            name: "unit",
            label: t("unit"),
            options: unitOptions,
            isClearable: true,
          },
          {
            type: "bool",
            name: "reuse",
            label: t("reuse"),
          },
          {
            name: "sample_data",
            label: t("sample_data"),
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "text",
            name: "observation",
            label: t("observation") + " (" + t("for_observations") + ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "text",
            name: "assessment",
            label: t("assessment") + " (" + t("for_assessments") + ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "choice",
            name: "dangerous_answer",
            label:
              t("dangerous_answer") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            required: false,
            error: "error",
            options: [
              { label: t("yes"), value: "yes" },
              { label: t("no"), value: "no" },
            ],
          },
          {
            type: "text",
            name: "possible_remedy",
            label:
              t("possible_remedy") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "choice",
            name: "danger_potential",
            label:
              t("danger_potential") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            required: false,
            error: "error",
            options: [
              { label: t("low"), value: "low" },
              { label: t("mid"), value: "mid" },
              { label: t("high"), value: "high" },
            ],
          },
        ]}
      />
      {renderTemplates()}
    </>
  );
};

export default CreateMeasurementDefinition;
