import { Typography } from "@mui/material";
import { t } from "i18next";
import "../styles/headerBar.css";

export default function PortalNameArea() {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    const portalNames: Record<string, string> = {
        admin: t("kaer_staff_portal_name"),
        manage: t("kaer_manager_portal_name"),
        portal: t("kaer_employee_portal_name"),
    };

    return (
        <Typography variant="h5" className="portalNameWrapper">
            {portalNames[subdomain] || subdomain}
        </Typography>
    );
}
