import { ErrorMessage } from "@hookform/error-message";
import { DatePicker } from "@mui/x-date-pickers";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";
import dayjs from "dayjs";
import styles from "./genericDatePicker.module.css";
import { FormControlLabel } from "@mui/material";

interface Props {
  name: string;
  label: string;
  predefinedvalue?: any;
}

const GenericDatePicker: FC<Props> = ({ name, label, predefinedvalue }) => {
  const [dateValue, setDateValue] = useState<any>(null);
  const { setValue, control, trigger } = useFormContext();

  useEffect(() => {
    if (predefinedvalue) {
      const date = dayjs(predefinedvalue);
      setDateValue(date);
      setValue(name, predefinedvalue);
    }
  }, [predefinedvalue, setValue, name]);

  const onChangeHandler = (newDate: any, onChange?: any) => {
    if (newDate) {
      const tempDate = dayjs(newDate.$d).format("DD.MM.YYYY");
      onChange(tempDate);
    } else {
      onChange("");
    }
  };

  const handleClearDate = () => {
    setDateValue(null);
    setValue(name, "");
    trigger(name);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: t("birthdate_required_error"),
        }}
        render={({
          field: { onChange, ...rest },
          fieldState: { error },
          formState: { errors },
        }) => (
          <div>
            <FormControlLabel
              labelPlacement="top"
              sx={{
                margin: "0",
                width: "100%",
                alignItems: "start",
                fontFamily: "inherit !important",
              }}
              disableTypography
              label={
                <section style={{ margin: "0 0 0.25rem" }}>{label}</section>
              }
              control={
                <DatePicker
                  {...rest}
                  value={dateValue}
                  onChange={(newValue) => {
                    onChangeHandler(newValue, onChange);
                    setDateValue(newValue);
                  }}
                  slotProps={{
                    textField: {
                      name: t("birthdate"),
                      error: !!error,
                      className: styles.fullWidth,
                      InputProps: {
                        classes: { root: styles.outlinedInputRounded },
                      },
                    },
                    field: {
                      clearable: true,
                      onClear: () => handleClearDate(),
                    },
                  }}
                />
              }
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />
          </div>
        )}
      />
    </>
  );
};

export default GenericDatePicker;
