import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { TimeSlots } from "./timeSlots";
import { useNavigate, useParams } from "react-router-dom";
import getDay from "date-fns/getDay";
import { Backdrop, Divider, Paper } from "@mui/material";
import { t } from "i18next";
import de from "date-fns/locale/de";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { DotLoader } from "react-spinners";
import TimeSlotPickConfirmation from "./timeSlotPickConfirmation";
import styles from "./appointmentRequestSlots.module.css";
import dayjs from "dayjs";

export function AppointmentRequestSlots() {
  const [startDate, setStartDate] = useState<Date | undefined | null>(null);
  const [daySet, setDaySet] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [responseConfirmationInformation, setResponseConfirmationInformation] =
    useState<any>();

  registerLocale("de", de);
  const navigate = useNavigate();

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };

  const handleShowConfirmationAfterPick = () => {
    setShowConfirmation(true);
  };

  const handleGetResponseAfterPick = (data: any) => {
    setResponseConfirmationInformation(data);
  };

  useEffect(() => {
    if (appointmentrequestId !== "") {
      api.genericApiRequest({
        method: "get",
        entity: "appointmentRequest",
        entityId: appointmentrequestId,
        successHandler: (res: any) => {
          const availableDays = res?.data?.available_day_set || [];
          if (availableDays.length) {
            const transformedDays = availableDays.map((date: any) =>
              dayjs(date)
                .hour(12)
                .minute(0)
                .second(38)
                .millisecond(941)
                .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            );

            setDaySet(transformedDays);
            setStartDate(dayjs(availableDays[0]).toDate());
          } else {
            setError(t("error_all_slots_taken"));
          }

          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        parametersToRender: { depth: "0" },
      });
    }
  }, [appointmentrequestId]);

  const intervals = daySet.map((day: any) => ({
    start: dayjs(day).subtract(1, "day").toDate(),
    end: dayjs(day).toDate(),
  }));

  return (
    <>
      {isLoading ? (
        <Backdrop open={true} className={styles.backdrop}>
          <DotLoader
            color="#8c1ec8"
            size={65}
            cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
          />
        </Backdrop>
      ) : !showConfirmation ? (
        <Paper
          className={styles.timeSlotsWrapper}
          style={{ height: startDate ? "" : styles.fullHeight }}
        >
          <h1 className={styles.heading}>{t("choose_date_time")}</h1>
          <DatePicker
            locale={"de"}
            calendarStartDay={1}
            inline
            disabledKeyboardNavigation
            filterDate={isWeekday}
            includeDateIntervals={intervals}
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />
          {startDate && (
            <>
              <Divider />
              <TimeSlots
                selectedDay={startDate}
                handleGetResponseAftetPick={handleGetResponseAfterPick}
                handleShowConfirmationAfterPick={
                  handleShowConfirmationAfterPick
                }
              />
            </>
          )}
        </Paper>
      ) : (
        <TimeSlotPickConfirmation
          appointmentInformation={responseConfirmationInformation}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={
            error === t("error_all_slots_taken")
              ? t("hint")
              : t("error_occurred")
          }
          error={error}
          onClosehandler={() => {
            setError("");
            if (error === t("error_all_slots_taken")) {
              navigate("/appointmentrequestopen");
            }
          }}
        />
      )}
    </>
  );
}
