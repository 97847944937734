import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { t } from "i18next";
import styles from './fileErrorModal.module.css';

interface Props {
  uploadedFilesValues?: any;
  error?: any;
  onErrorConfirm?: any;
}

const FileErrorModal: FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(props.error);

  const handleClose = () => {
    setOpen(false);
    if (props.onErrorConfirm) {
      props.onErrorConfirm();
    }
  };

  const err = [];
  for (const key in props.uploadedFilesValues) {
    const file = props.uploadedFilesValues[key];
    const fileName = file.file;
    const status = file.status;
    const error = file?.error;
    const message = error?.response?.data?.file;

    // if (
    //   props.uploadedFilesValues.data["file"] &&
    //   props.uploadedFilesValues.data["file"].includes("Unsupported")
    // ) {
    //   message = "Unzulässiger Dateityp";
    // } else if (Array.isArray(props.uploadedFilesValues.data[key])) {
    //   message = props.uploadedFilesValues.data[key];
    // } else if (typeof props.uploadedFilesValues.data[key] === "string") {
    //   message = props.uploadedFilesValues.data[key];
    // } else if (typeof props.uploadedFilesValues.data[key] === "object") {
    //   message =
    //     props.uploadedFilesValues.status +
    //     ": " +
    //     props.uploadedFilesValues.statusText;
    // }

    err.push(
      <div key={fileName} data-testid="fileErrorModal-item">
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            {!status && <ErrorOutlineIcon className={styles.errorIcon} data-testid={`error-icon-${fileName}`} />}
            {status && <CheckCircleOutlineIcon className={styles.successIcon} data-testid={`success-icon-${fileName}`} />}
          </ListItemAvatar>
          <ListItemText
            primary={t(fileName)}
            primaryTypographyProps={{ className: styles.listItem }}
            secondary={
              <>
                <Typography
                  className={styles.errorMessage}
                  component="span"
                  variant="body2"
                  data-testid={`error-message-${fileName}`}
                >
                  {message}
                </Typography>
              </>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="fileErrorModal-modal"
    >
      <Box className={styles.modalBox}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modalTitle}
        >
          {t("error_occurred")}
        </Typography>
        <Typography
          component={List}
          id="modal-modal-description"
          className={styles.modalList}
          data-testid="fileErrorModal-list"
        >
          {err}
        </Typography>
        <Button
          onClick={handleClose}
          data-testid="fileErrorModal-confirmBtn"
        >
          {t("confirm")}
        </Button>
      </Box>
    </Modal>
  );
};

export default FileErrorModal;
