import { useState } from "react";
import { Editor } from "../../components/template/editor";
import Modal from "../../components/modal";
import { t } from "i18next";
import Restricted from "../../consumer/restricted";
import styles from "./templateEditor.module.css";
import GenericButton from "../../components/forms/inputs/button/genericButton";

export function TemplateEditor() {
  const [editorCount, setEditorCount] = useState<any>([
    {
      id: String(0),
    },
  ]);
  const [editorId, setEditorId] = useState<number>(0);
  const [exampleModalOpen, setExampleModalOpen] = useState<boolean>(false);

  const addEditor = () => {
    setEditorCount(
      editorCount.concat([
        {
          id: String(editorCount.length) + String(editorId),
        },
      ])
    );
    setEditorId(editorId + 1);
  };

  const duplicateEditor = (data?: any) => {
    setEditorCount(
      editorCount.concat([
        {
          id: String(editorCount.length) + String(editorId),
          data: data ? data : "",
        },
      ])
    );
    setEditorId(editorId + 1);
  };

  const removeEditor = (id: any) => {
    const newItems = editorCount.filter((item: any) => item.id !== id);
    setEditorCount(newItems);
  };

  const modalContent = (
    <>
      <ul>
        <li>
          {t("display_master_data")}:
          <br />
          {
            "{{ blob.metrics.stammdaten_basis_adresse_strasse_hausnummer.value }}"
          }
        </li>
        <li>
          {t("display_measurement_definition_with_unit")}: <br />
          {
            "{{ blob.measurements.handschuhe_bei_der_arbeit.value }} {{ blob.measurements.handschuhe_bei_der_arbeit.unit }}"
          }
        </li>
        <li>
          {t("date_at_pdf_creation")}: <br /> {"{% add_to_date %}"}
        </li>
        <li>
          {t("date_at_pdf_creation_plus_2_years")}: <br />
          {"{% add_to_date days=0 months=0 years=2 %}"}
        </li>
        <li>
          {t("date_of_examination")}: <br />
          {"{% add_to_date date=blob.appointment.datetime %}"}
        </li>
        <li>
          {t("date_of_examination_plus_14_days")}: <br />
          {"{% add_to_date date=blob.appointment.datetime days=14 %}"}
        </li>
        <li>
          {t("name_of_subject")}: <br />
          {
            "{{ subject.person.user.first_name }} {{ subject.person.user.last_name }}"
          }
        </li>
        <li>
          {t("name_of_subjects_company")}: <br /> {"{{ subject.company.name }}"}
        </li>
        <li>
          {t("name_and_email_of_doctor_who_set_appointment_on_review")}:
          <br />
          {
            "{{ blob.appointment.modified_by.person.user.first_name }} {{ blob.appointment.modified_by.person.user.last_name }} ({{ blob.appointment.modified_by.person.user.email }})"
          }
        </li>
        <li>
          {t("if_then_query")}:
          <br />
          {
            "{% if blob.measurements.handschuhe_bei_der_arbeit.value == 'yes' %}"
          }
          <br />
          {"      Du trägst handschuhe bei der Arbeit"}
          <br />
          {"  {% else %}"}
          <br />
          {"      Du trägst keine handschuhe bei der Arbeit"}
          <br />
          {"  {% endif %}"}
          <br />
        </li>
        <li>
          {t("search_and_replace_in_text_or_variable")}:{" "}
          {
            '{% replace input_text="Das ist ein Beispiel" search="Beispiel" replace_with="toller Tag" %}'
          }
        </li>
        <li>
          {t(
            "search_and_replace_in_text_or_variable_and_write_result_to_new_variable"
          )}
          :
          <br />
          {
            '{% replace input_text="Das ist ein Beispiel" search="Beispiel" replace_with="toller Tag"  as new_variable %}'
          }
        </li>
        <li>
          {t("multiple_search_and_replace_in_text_or_variable")}:{" "}
          {
            '{% replace_multi input_text="Das ist ein Beispiel" search_and_replace="Das>>Heute||Beispiel>>toller Tag" %}'
          }
        </li>
        <li>
          {t("create_new_variable_overwrites_previous_variable")}:{" "}
          {'{% get "Das ist ein Beispiel" as new_variable %}'}
        </li>
      </ul>
    </>
  );

  return (
    <Restricted permissionRequired="frontend_view_template">
      <div>
        <h1>{t("template_editor")}</h1>
        <div className={styles.buttonWrapper}>
          <GenericButton onClick={() => setExampleModalOpen(true)}>
            {t("example")}
          </GenericButton>
          <GenericButton onClick={addEditor}>{t("add_editor")}</GenericButton>
        </div>

        <form className={styles.form}>
          <div className={styles.editorWrapper}>
            {editorCount.map((item: any) => (
              <Editor
                id={item.id}
                key={item.id}
                data={item.data}
                onRemoveEditor={removeEditor}
                // editorCount={editorCount}
                onDuplicateEditor={duplicateEditor}
              />
            ))}
          </div>
        </form>
        <Modal
          open={exampleModalOpen}
          hideSubmitButton={true}
          cancelButtonText={t("ok")}
          title="Beispiele"
          onClose={() => setExampleModalOpen(false)}
        >
          {modalContent}
        </Modal>
      </div>
    </Restricted>
  );
}
