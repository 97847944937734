export function previewFile(row: any, childrenSubjectId: string) {
  let url = "/api/v1/file/" + `${row.getValue("id")}` + "/view/";

  if (childrenSubjectId !== "") {
    url =
      "/api/v1/file/" +
      `${row.getValue("id")}` +
      "/download/?impersonate_subject=" +
      `${childrenSubjectId}`;
  }
  return url;
}

export function formatFilenameString(fileName: any) {
  let validFileName;

  if (typeof fileName === "object") {
    validFileName = fileName.props?.["aria-label"];
  } else if (typeof fileName === "string") {
    validFileName = fileName;
  }
  let stringWithSpaces = validFileName.replace(/_+/g, " ");

  const dateRegex = /(\d{2,4}-\d{1,2}-\d{1,2})$/;
  const match = stringWithSpaces.match(dateRegex);

  if (match) {
    const dateString = match[1];
    const [year, month, day] = dateString.split("-");
    const formatedDate = `${day}.${month}.${year}`;
    stringWithSpaces = stringWithSpaces.replace(dateRegex, formatedDate);
  }
  return stringWithSpaces;
}
