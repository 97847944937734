import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCheckDouble,
  faListCheck,
  faMagnifyingGlassArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { MouseEventHandler } from "react";
import { SizeProp } from "@fortawesome/fontawesome";

interface Props {
  backgroundColor?: string;
  icon?: string;
  tooltip?: string;
  onClick?: MouseEventHandler;
  iconSize?: SizeProp;
  buttonHeight?: string;
  buttonWidth?: string;
  borderRadius?: string;
}

export default function BigRoundButton(props: Props) {
  let backgroundColor = "#FF6428";

  if (props.backgroundColor != undefined) {
    backgroundColor = props.backgroundColor;
  }

  let buttonHeight = "48px";
  let buttonWidth = "48px";
  if (props.buttonHeight) {
    buttonHeight = props.buttonHeight;
  }
  if (props.buttonWidth) {
    buttonWidth = props.buttonWidth;
  }

  let borderRadius = "50%";
  if (props.borderRadius) {
    borderRadius = props.borderRadius;
  }

  const buttonStyle = {
    backgroundColor: backgroundColor,
    width: buttonWidth,
    height: buttonHeight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "11px 13px",
    borderRadius: borderRadius,
    cursor: "pointer",
  } as React.CSSProperties;

  const buttonIconStyle = {} as React.CSSProperties;

  const iconMapping: any = {
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight,
    checkmark: faCheck,
    doubleCheckmark: faCheckDouble,
    magnifyArrowRight: faMagnifyingGlassArrowRight,
    listCheck: faListCheck,
  };

  let iconName = "arrowRight";
  if (props.icon && props.icon in iconMapping) {
    iconName = props.icon;
  }
  let iconSize: SizeProp = "lg";
  if (props.iconSize) {
    iconSize = props.iconSize;
  }

  return (
    <div title={props.tooltip} className="itemAction" onClick={props.onClick} role="button">
      <div style={buttonStyle} data-testid="big-round-button">
        <FontAwesomeIcon
          icon={iconMapping[iconName]}
          size={iconSize}
          style={buttonIconStyle}
          color="#FFF"
        />
      </div>
    </div>
  );
}
